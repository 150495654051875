import "./PlayingGamesWidget.scss";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getPlayingGames } from "../../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Table } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { parseISO } from "date-fns";
import { useUserStore } from "../../../stores";

export type PlayingGamesWidgetProps = {
	className?: string;
}

const PlayingGamesWidget: React.FC<PlayingGamesWidgetProps> = ({ className }) => {
	const [games, setGames] = useState<any[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [lastUpdate, setLastUpdate] = useState<any>(new Date());
	const { user } = useUserStore();

	const { isLoading } = useQuery("getPlayingGames", () => {
		getPlayingGames().then(([result]) => {
			setGames(result?.data || []);
			setTotal(result?.total || 0);
			setLastUpdate(new Date());
		});
	},
		{
			refetchOnWindowFocus: true,
			refetchInterval: () => { return user ? 30000 : false; }
		});

	return (
		<div className="widget playing-games-widget">
			<Card className={className}>
				<div className="card-header"><FontAwesomeIcon icon="gamepad" className={total >= 0 ? "text-success" : ""} /> Playing games</div>
				<div className="card-body d-flex flex-column" title="Order by last played time, refresh in 30s">
					<div className=" mb-1"><b>{total}</b> games.</div>
					<small>Last update: {lastUpdate.toLocaleString()}</small>
					<div className="table-responsive">
						<Table className="table-striped mt-1" hover>
							<thead>
								<tr className="text-capitalize">
									<th><span className="datatable-sorter">Game</span></th>
									<th><span className="datatable-sorter">Players</span></th>
									<th><span className="datatable-sorter">Last played</span></th>
								</tr>
							</thead>
							<tbody>
								{
									!isLoading && games?.length > 0
									&& games.map((item: any) => {
										return (
											<tr key={item.id}>
												<td><Link to={`/playing-by-periods/${item.id}`}>{item.name}</Link></td>
												<td>{item.totalPlayer}</td>
												<td>{parseISO(item.lastPlayed).toLocaleString()}</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</div>
				</div>
				<div className="card-footer d-flex align-items-center justify-content-end">
					<NavLink className="small" to="/online-players">View Details</NavLink>
					<div className="small"><FontAwesomeIcon icon={"angle-right"} /></div>
				</div>
			</Card>
		</div>
	);
}

PlayingGamesWidget.displayName = "PlayingGamesWidget";

export default React.memo(PlayingGamesWidget);