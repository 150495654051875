import React, { useState } from 'react';

import './SystemTrace.scss';
import { useEffectOnce } from 'usehooks-ts';
import { usePageStore } from '../../stores';
import { useSearchParams } from 'react-router-dom';
import { SystemTraceFilter, SystemTraceFilterModel, SystemTraceResult, SystemTraceResultModel, SystemTraceItemModal } from '../../components/SystemTrace';
import { ListResult } from '../../models';
import { getLogs } from '../../services';

const SystemTrace: React.FC = () => {
	const { setPage } = usePageStore();
	const [searchParams, setSearchParams] = useSearchParams();
	const [result, setResult] = useState<ListResult<SystemTraceResultModel>>();
	const [filter, setFilter] = useState<SystemTraceFilterModel>();
	const [targetItem, setTargetItem] = useState<SystemTraceResultModel>();

	useEffectOnce(() => {
		setPage({ title: "System Trace", pagePath: ["Logs", "System Trace"] });
		setFilter(Object.fromEntries(searchParams) as SystemTraceFilterModel);
	});

	const filterChangeHandle = (filter) => {
		filter.page = 1;
		getData(filter);
		setSearchParams(filter);
	}

	const searchLinkChangeHandle = (filter: any) => {
		filter.page = 1;
		setFilter(filter);
		setSearchParams(filter);
		getData(filter);
	}

	const pageChangeHandle = function (page: number): void {
		setSearchParams((state) => {
			state.set("page", page.toString());
			getData(Object.fromEntries(state) as SystemTraceFilterModel);
			return state;
		});
	}

	const pageSizeChangeHandle = (pageSize: number) => {
		setSearchParams((state) => {
			state.set("pageSize", pageSize.toString());		
			getData(Object.fromEntries(state) as SystemTraceFilterModel);
			return state;
		});
	}

	const getData = (data: SystemTraceFilterModel) => {
		getLogs(data).then(([list]) => {
			setResult({ data: list?.data ?? [], total: list?.total ?? 0 })
		});
	}

	return (
		<div className="system-trace">
			<SystemTraceFilter
				defaultFilter={filter}
				onFilterChange={filterChangeHandle} />
			<SystemTraceResult result={result}
				defaultPage={searchParams.get("page")}
				defaultFilter={filter}
				searchByCol={searchLinkChangeHandle}
				pageSizeChangeHandle={pageSizeChangeHandle}
				pageChangeHandle={pageChangeHandle}
				viewDetailHandle={setTargetItem}
			/>
			{
				!targetItem ? null :
					<SystemTraceItemModal
						item={targetItem}
						onClose={() => setTargetItem(undefined)}

					/>
			}
		</div>
	);
}

SystemTrace.displayName = 'SystemTrace';

export default React.memo(SystemTrace);