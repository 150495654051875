import React, { useCallback, useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type AddUserCasinoModalProps = {
	casinoId: string;
	isSaving: boolean;
	onSubmit: (data: any) => void
	onClose: () => void
}

const AddUserCasinoModal: React.FC<AddUserCasinoModalProps> = ({ onClose, onSubmit, casinoId, isSaving }) => {
	const [email, setEmail] = useState<string>();
	const [userName, setUserName] = useState<string>();

	const submitHandle = useCallback(() => {
		onSubmit({ email, userName, casinoId });
	}, [email, userName, casinoId]);

	return (
		<div className="add-user-casino-modal">
			<Modal isOpen={true} size="lg">
				<ModalHeader toggle={onClose}>Add user for {casinoId}</ModalHeader>
				<ModalBody>
					<div className="row">
						<div className="col-12">
							<FormGroup floating>
								<Input
									name="email"
									placeholder="Emaill"
									type="email"
									required
									maxLength={100}
									onChange={e => setEmail(e.target.value)}
								/>
								<Label for="email">
									Emaill
								</Label>
							</FormGroup>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<FormGroup floating>
								<Input
									name="userName"
									placeholder="Name"
									type="text"
									required
									maxLength={100}
									onChange={e => setUserName(e.target.value)}
								/>
								<Label for="userName">
									Name
								</Label>
							</FormGroup>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="warning" type="button" disabled={!email || !userName || isSaving} onClick={submitHandle}>
						<span>Submit</span> { isSaving && <FontAwesomeIcon icon="spinner" className="fa-spin" /> }
					</Button>{' '}
					<Button color="secondary" onClick={onClose}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

AddUserCasinoModal.displayName = 'AddUserCasinoModal';

export default React.memo(AddUserCasinoModal);