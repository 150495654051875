import './CachedItemList.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { SearchBar } from '../../components/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PagingPanel } from '../../components';
import { ListResult } from '../../models';
import { useEffectOnce, useToggle } from 'usehooks-ts';
import { getCacheKeys, getCacheKeyDetail, deleteCacheByKey } from '../../services';
import { usePageStore } from '../../stores';
import { toast } from 'react-toastify';
import { PurgeCacheItemModal } from '../../components/PurgeCacheItemModal';

export type CachedItemListProps = {
}

const CachedItemList: React.FC<CachedItemListProps> = () => {
	const [isLoading, toggleLoading] = useToggle();
	const [filter, setFilter] = useState<any>({ pageSize: 20 });
	const [cacheData, setCacheData] = useState<string>("{}");
	const [isLoadingDetail, toggleLoadingDetail] = useToggle();
	const [cacheKey, setCacheKey] = useState<string>();
	const [cacheItem, setCacheItem] = useState<ListResult<string>>();
	const [isOpenModal, setIsOpenModal] = useState(false);
	const { setPage } = usePageStore();

	const getCaches = () => {
		toggleLoading();

		getCacheKeys(filter).then(([data]) => {
			toggleLoading();
			setCacheItem(data);
		})
	} 

	useEffectOnce(() => {
		setPage({ title: "Cached Items", pagePath: ["Cached items"] });
	});

	useEffect(() => {
		getCaches();
	}, [filter])

	const closeModal = useCallback(() => {
		setIsOpenModal(false)
	}, []);

	const viewCacheDataHandle = (key) => {
		setCacheKey(key);
		setIsOpenModal(true);
		toggleLoadingDetail();

		getCacheKeyDetail(key).then(([data]) => {
			toggleLoadingDetail();
			if (!data) {
				setCacheData("Not found.");
				return;
			}

			setCacheData(data);
		})
	}

	const purgeItemHandle = () => {
		if (!cacheKey) {
			return;
		}

		// eslint-disable-next-line no-restricted-globals
		if (!confirm("Purge this key?")) {
			return;
		}

		toggleLoadingDetail();
		deleteCacheByKey(cacheKey).then(([isUpdated]) => {
			toggleLoadingDetail();
			toast(isUpdated ? "Cache item is purged" : "Key not found.", { type: "info" });
			closeModal();
			getCaches();
		})
	}

	return (
		<div className="cached-item-list">
			<Card className="card mb-4">
				<CardHeader>
					<div className="row">
						<div className="col-md-3">
							<SearchBar
								onKeywordChange={(e) => setFilter({ ...filter, keyword: e, page: 1 })}
								defaultKeyword={filter.keyword}
								title="Find cache" />
						</div>
					</div>
				</CardHeader>
				<CardBody className="card-body table-responsive">
					{
						isLoading && <p className="text-center">
							<FontAwesomeIcon icon="spinner" className="fa-spin" />
						</p>
					}
					<Table className="table-striped" hover>
						<thead>
							<tr className="text-capitalize">
								<th>Key</th>
								<th>Data</th>
							</tr>
						</thead>
						<tbody>
							{
								cacheItem?.data && cacheItem.data.length > 0
								&& cacheItem.data.map((item) => {
									return (
										<tr key={item}>
											<td>{item}</td>
											<td><a href="#" onClick={(e) => {
												e.preventDefault();
												viewCacheDataHandle(item);
											}}><FontAwesomeIcon icon="eye" /> View</a></td>
										</tr>
									);
								})}
						</tbody>
					</Table>
				</CardBody>
				<CardFooter>
					{
						cacheItem &&
						<PagingPanel total={cacheItem.total}
							pageSize={filter.pageSize}
							page={filter.page}
							onChange={(e) => setFilter({ ...filter, page: e })}
							onPageSizeChange={(e) => setFilter({ ...filter, pageSize: e })}
						/>}
				</CardFooter>
			</Card>
			{
				!isOpenModal ? null :
					<PurgeCacheItemModal
						cacheKey={cacheKey}
						cacheData={cacheData}
						isLoadingDetail={isLoadingDetail}
						onClose={closeModal}
						onSubmit={purgeItemHandle} />
			}
		</div>
	);
}

CachedItemList.displayName = 'CachedItemList';

export default React.memo(CachedItemList);