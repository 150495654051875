import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type PurgeCacheItemModalProps = {
	cacheKey: string | undefined,
	cacheData: any,
	isLoadingDetail: boolean,
	onSubmit: () => void
	onClose: () => void
}

const PurgeCacheItemModal: React.FC<PurgeCacheItemModalProps> = ({ cacheKey, cacheData, onSubmit, onClose, isLoadingDetail }) => {
	return (
		<div className="purge-cache-item-modal">
			<Modal isOpen={true} size="lg">
				<ModalHeader toggle={onClose}>{cacheKey}</ModalHeader>
				<ModalBody>
					{isLoadingDetail ? <p className="text-center"><FontAwesomeIcon icon="spinner" className="fa-spin" /></p> :
						<pre className="cache-data">{cacheData}</pre>
					}
				</ModalBody>
				<ModalFooter>
					<Button color="warning" type="button" disabled={!cacheKey} onClick={onSubmit}>
						Purge
					</Button>{' '}
					<Button color="secondary" onClick={onClose}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

PurgeCacheItemModal.displayName = 'PurgeCacheItemModal';

export default React.memo(PurgeCacheItemModal);