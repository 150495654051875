import "./AddEditGameForm.scss";
import "react-quill/dist/quill.snow.css";
import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { ImageUpload, DropdownCombobox, BadgeTagList } from "..";
import { Card, CardBody, CardFooter, CardHeader, FormGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GameImage, GameInfo } from "../../models";
import { useEffectOnce } from "usehooks-ts";
import { getAllTags } from "../../services";
import ImageBox from "../ImageBox";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import { useUserStore } from "../../stores";

export type AddEditGameFormProps = {
	selectedGame?: GameInfo;
	onSubmit: (game: GameInfo) => void;
}

const AddEditGameForm: React.FC<AddEditGameFormProps> = ({ selectedGame, onSubmit }) => {
	const { user } = useUserStore();
	const [game, setGame] = useState<GameInfo>(selectedGame || { config: "{}" } as GameInfo);
	const [tagSource, setTagSource] = useState<string[]>([]);
	const quillRef = useRef<any>();

	//const imageHandler = () => {
	//	let value = prompt("Image url");

	//	console.log(quillRef.current);
	//	return;
	//	let range = quillRef.current.selection;
	//	if (value) {
	//		quillRef.current.insertEmbed(range.index, "image", value, quillRef.current.sources.USER);
	//	}
	//}

	const editorToolbarModules = useMemo<any>(() => {
		return {
			toolbar: {
				container: [
					[{ 'header': [1, 2, false] }],
					["bold", "italic", "underline", "strike", "blockquote"],
					[{ 'size': ["small", false, "large", "huge"] }],
					[{ 'color': [] }, { 'background': [] }, { 'font': [] }, { 'align': [] }],
					[{ 'list': "ordered" }, { 'list': "bullet" }],
					[{ 'indent': "-1" }, { 'indent': "+1" }],
					["link", "image", "video"],
					["clean"]
				],
				//handlers: {
				//	image: imageHandler
				//}
			},
		};
	}, []);

	const editorToolbarFormats = useMemo<string[]>(() => [
		"header",
		"bold", "italic", "underline", "strike", "blockquote",
		"list", "bullet", "indent",
		"link", "image"
	], []);

	//useEffectOnce(() => {
		//getAllTags().then(([result]) => {
		//	setTagSource(result || []);
		//});

		//let images = game.imgUrls;

		//if (!images) {
		//	images = [];
		//}

		//if (images.length == 0 && game.coverImage) {
		//	images = [{ imageUrl: game.coverImage } as GameImage];
		//}

		//if (!game.imgUrls || images.length != game.imgUrls.length) {
		//	setGame({ ...game, imgUrls: [...images] });
		//}
	//});

	//const onUploadImageDoneHandler = useCallback((data: GameImage[]) => {
	//	setGame(state => {
	//		//const images = [...(state.imgUrls || [])];

	//		//if (images.length == 0) {
	//		//	images.push(...data);
	//		//	state.coverImage = images[0].imageUrl;
	//		//} else {
	//		//	data.forEach((item) => {
	//		//		const existed = images.find(o => item.imageUrl == o.imageUrl);

	//		//		if (!existed) {
	//		//			images.push(item);
	//		//		}
	//		//	});
	//		//}
	//		const images = [];

	//		return { ...state, imgUrls: images };
	//	});
	//}, [game.imgUrls]);

	//const selectCoverHandle = useCallback((image) => {
	//	if (image.imageUrl != game.coverImage) {
	//		setGame({ ...game, coverImage: image.imageUrl });
	//	}
	//}, [game])

	//const removeImageHandle = useCallback((image: GameImage) => {
	//	const images = [...(game.imgUrls || [])];

	//	const i = images.findIndex(o => o.imageUrl == image.imageUrl);

	//	if (i > -1) {
	//		images.splice(i, 1);
	//	}

	//	setGame(state => {
	//		state.imgUrls = images;
	//		return { ...state }
	//	});
	//}, [game.imgUrls]);

	//const tagSelectHandle = useCallback((data: any[]) => {
	//	if (!data?.length) {
	//		return;
	//	}

	//	const tags = [...(game.tags || [])];

	//	data.forEach((item) => {
	//		if (tags.indexOf(item.label || item) < 0) {
	//			tags.push(item.label || item);
	//		}
	//	});

	//	setGame((state) => {
	//		state.tags = tags;
	//		return { ...state };
	//	});
	//}, [game.tags]);

	//const removeTagHandle = useCallback((tag: string) => {
	//	const tags = [...game.tags];
	//	const existedIndex = tags.indexOf(tag);

	//	if (existedIndex > -1) {
	//		tags.splice(existedIndex, 1);
	//	}

	//	setGame((state) => {
	//		state.tags = tags;
	//		return { ...state };
	//	});
	//}, [game.tags]);

	const resetHandle = useCallback(() => {
		if (selectedGame)
			setGame(selectedGame);
	}, [selectedGame]);

	const formSubmitHandle = useCallback((e) => {
		e.preventDefault();

		try {
			if (!game.config.startsWith("{") || !game.config.endsWith("}")) {
				toast("Invalid config format", { type: "error" });
				return;
			}

			const config = JSON.parse(game.config);

			if (!config) {
				toast("Invalid config format", { type: "warning" });
				return;
			}
		} catch (e) {
			toast("Invalid config format", { type: "warning" });
			return;
		}

		//if (!game.tags || game.tags.length == 0) {
		//	toast("Add some tag", { type: "warning" });
		//	return;
		//}
		//if (game.imgUrls.length == 0) {
		//	toast("Add at least an image", { type: "warning" });
		//	return;
		//}

		setGame((state) => {
			//if (!state.coverImage) {
			//	state.coverImage = state.imgUrls[0].imageUrl;
			//}

			//if (!state.fullDescription) {
			//	state.fullDescription = state.description;
			//}

			onSubmit(state);
			return state;
		});
	}, [game]);

	return (
		<div className="edit-game-form">
			<form onSubmit={formSubmitHandle}>
				<Card className="mb-3">
					<CardHeader>
						<div className="row">
							<div className="col-md-2 ms-auto d-flex">
								<Link className="btn btn-secondary btn-small ms-auto" color="secondary" to="/games">Back</Link>
							</div>
						</div>
					</CardHeader>
					<CardBody>
						<div className="row">
							<div className="col-md-4">
								<div className="row">
									<div className="col-md-12">
										<FormGroup floating>
											<input
												type="text"
												name="gameName"
												placeholder="Name"
												value={game.name || ""}
												required
												onChange={e => setGame({ ...game, name: e.target.value })}
												onBlur={
													e => {
														if (!game.id) {
															setGame({ ...game, id: e.target.value.replaceAll(" ", "").replaceAll("'s", "") });
														}
													}
												}
												className=" form-control"
												maxLength={100}
											/>
											<label className="form-label" htmlFor="name">Name</label>
										</FormGroup>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										{
											selectedGame && <div className="d-flex flex-row align-items-center">
												<label className="form-label mb-0">GameId</label>
												<a href={`${selectedGame.gameUrl}?playerId=${user?.userEmail}`} target="_blank" className="ms-1 text-decoration-none"> {selectedGame.id} <FontAwesomeIcon icon="external-link" title="Open game" /></a>
											</div>
										}
										{
											!selectedGame &&
											<FormGroup floating>
												<input
													type="text"
													name="GameId"
													required
													value={game.id || ""}
													onChange={e => setGame({ ...game, id: e.target.value })}
													placeholder="Game Id"
													className="form-control"
													maxLength={100}
												/>
													<label className="form-label">Game id</label>
											</FormGroup>
										}
									</div>
								</div>
							</div>

							<div className="col-md-4">
								<FormGroup floating>
									<input
										name="country"
										value={game.country || ""}
										onChange={e => setGame({ ...game, country: e.target.value })}
										required
										placeholder="Country"
										className="form-control"
									/>
									<label className="form-label">Country</label>
								</FormGroup>
							</div>
							<div className="col-md-4">
								<FormGroup floating>
									<input
										name="status"
										value={game.status || ""}
										onChange={e => setGame({ ...game, status: e.target.value })}
										required
										placeholder="Status"
										className="form-control"
									/>
									<label className="form-label">Status</label>
								</FormGroup>
							</div>
						</div>
						<hr />
						<div className="row">
								<div className="col-md-6">
									<FormGroup floating>
										<input
											type="url"
											name="clientUrl"
											value={game.clientUrl || ""}
											required
											onChange={e => setGame({ ...game, clientUrl: e.target.value })}
											placeholder="Game Url"
											className="form-control"
											maxLength={512}
										/>
										<label className="form-label">Game url</label>
									</FormGroup>
								</div>
								<div className="col-md-6">
									<FormGroup floating>
										<input
											type="url"
											name="historyUrl"
											value={game.historyUrl || ""}
											required
											placeholder="Game History Url"
											onChange={e => setGame({ ...game, historyUrl: e.target.value })}
											className="form-control"
											maxLength={512}
										/>
										<label className="form-label">Game history url</label>
									</FormGroup>
								</div>
						</div>
						<div className="row">
							<div className="col-md-12">
									<FormGroup floating>
										<textarea
											name="config"
											value={game.config || "{}"}
											onChange={e => setGame({ ...game, config: e.target.value })}
											required
											placeholder="Config"
											className="form-control textarea-resizeable"
										/>
										<label className="form-label">Config (json)</label>
									</FormGroup>
								
							</div>
							
							{/*<div className="col-md-7">*/}
							{/*	<FormGroup floating>*/}
							{/*		<textarea placeholder="Short Description"*/}
							{/*			maxLength={256}*/}
							{/*			value={game.description || ""}*/}
							{/*			required*/}
							{/*			onChange={e => setGame({ ...game, description: e.target.value })}*/}
							{/*			name="description"*/}
							{/*			className="col-md-8 form-control textarea-resizeable"*/}
							{/*		/>*/}
							{/*		<label className="form-label">Short description</label>*/}
							{/*	</FormGroup>*/}
							{/*</div>*/}
						</div>
						<hr />
						{/*<div className="row mb-2">*/}
						{/*	<div className="col-md-5">*/}
						{/*		<div className="row  flex-row align-items-center">*/}
						{/*			<label className="col-md-2 form-label">Tag</label>*/}
						{/*			<div className="col-md-6">*/}
						{/*				<DropdownCombobox*/}
						{/*					id="tag-search"*/}
						{/*					placeholder="Search or add new"*/}
						{/*					options={tagSource}*/}
						{/*					onChange={(selected) => tagSelectHandle(selected)}*/}
						{/*					multiple={false}*/}
						{/*					allowNew={true} />*/}
						{/*			</div>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*	<div className="col-12 mt-3">*/}
						{/*		<BadgeTagList tags={game.tags} onRemove={removeTagHandle} />*/}
						{/*	</div>*/}
						{/*</div>*/}
						{/*<hr />*/}
						<div className="row mb-2">
							<div className="col-12">
								<label className="form-label">Image</label>
							</div>
							<div className="col-12">
								<FormGroup floating>
									<input
										type="url"
										name="coverImage"
										value={game.coverImage || ""}
										onChange={e => setGame({ ...game, coverImage: e.target.value })}
										required
										placeholder="coverImage"
										className="form-control"
									/>
									<label className="form-label">Cover Image</label>
								</FormGroup>
							</div>
				{/*			<div className="col-md-5 mx-auto mt-3">*/}
								{/*<ImageUpload onUploadFinish={onUploadImageDoneHandler} />*/}
							{/*</div>*/}
							{/*<div className="col-7 d-flex flex-row">*/}
								{/*{*/}
								{/*	!game?.imgUrls?.length ?*/}
								{/*		<div className="my-2 w-100 text-center">No image uploaded</div> :*/}
								{/*		game.imgUrls.map((image, i) => {*/}
								{/*			return (*/}
								{/*				<React.Fragment key={i}>*/}
								{/*					<ImageBox*/}
								{/*						src={image.imageUrl}*/}
								{/*						isSelected={image.imageUrl == game.coverImage}*/}
								{/*						onRemoveImageClick={e => removeImageHandle(image)}*/}
								{/*						onSelected={e => selectCoverHandle(image)}*/}
								{/*					/>*/}
								{/*				</React.Fragment>*/}
								{/*			);*/}
								{/*		})*/}
								{/*}*/}
							{/*</div>*/}
						</div>
						<div className="row mb-2">
							<div className="col-md-12">
								{/*<label className="form-label">Description</label>*/}
								{/*<ReactQuill*/}
								{/*	className="editor"*/}
								{/*	theme="snow"*/}
								{/*	ref={quillRef}*/}
								{/*	value={game.description || ""}*/}
								{/*	onChange={content => setGame({ ...game, description: content } as GameInfo)}*/}
								{/*	modules={editorToolbarModules}*/}
								{/*	formats={editorToolbarFormats}*/}
								{/*/>*/}

									<FormGroup floating>
										<textarea placeholder="Description"
											maxLength={256}
											value={game.description || ""}
											required
											onChange={e => setGame({ ...game, description: e.target.value })}
											name="description"
											className="col-md-8 form-control textarea-resizeable"
										/>
										<label className="form-label">Description</label>
									</FormGroup>
							</div>
						</div>
					</CardBody>
					<CardFooter className="d-flex justify-content-center">
						<button type="submit" className="btn btn-primary"><FontAwesomeIcon icon="save" />Save</button>
						{
							selectedGame &&
							<button type="button" className="btn btn-default" onClick={resetHandle} title="Reset all changes"><FontAwesomeIcon icon="refresh" /> Reset</button>
						}
					</CardFooter>
				</Card>
			</form>
		</div>
	);
}

export default memo(AddEditGameForm);