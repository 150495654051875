import React, { useState } from 'react';

import './GameDetail.scss';
import GameSpecs from '../../components/GameSpecs';
import { GameInfo } from '../../models';

export type GameDetailProps = {
}

const GameDetail: React.FC<GameDetailProps> = () => {
	const [game, setGame] = useState<GameInfo>();

	return (
		<div className="game-detail">
			{game && <GameSpecs game={game} />}
		</div>
	);
}

GameDetail.displayName = 'GameDetail';

export default React.memo(GameDetail);