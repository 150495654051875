import "./SignIn.scss";
import React, { useState } from "react";
import { useToggle, useEffectOnce } from "usehooks-ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiError, SignInModelType } from "../../models";
import { Link, useNavigate } from "react-router-dom";
import { usePageStore, useUserStore } from "../../stores";
import { toast } from "react-toastify";
import { getCurrentEnvironment, signIn } from "../../services";


const SignIn: React.FC = () => {
	const [signInData, setSignInData] = useState<SignInModelType>({} as SignInModelType);
	const [isShowPass, toggleShowPass] = useToggle(false);
	const [signInError, setSignInError] = useState<ApiError | null>();
	const { setUserInfo, user } = useUserStore();
	const navigate = useNavigate();
	const { setPage, setServerMode, currentServerMode } = usePageStore();

	useEffectOnce(() => {
        getCurrentEnvironment().then(([result]) => {
            setServerMode(result);
        });

		if (user?.id) {
			navigate("/");
			return;
		}

		setPage({ title: "Sign In", pagePath: [""] });
	});

	const handleTogglePassword = () => {
		toggleShowPass();
	}

	async function handleSubmit(e) {
		e.preventDefault();
		setSignInError(null);

		const [result, error] = await signIn(signInData);
		setSignInError(error);

		if (result?.id) {
			setUserInfo(result);
			toast("Welcome back, " + result.userName, { type: "success" });
			navigate("/", { replace: true });
		}
	}

	return (
		<div className="sign-in row justify-content-center">
			<span id="hero-decor" className="">GLIS GAMES</span>
			<div className="col-md-7 col-lg-5">
				<div className="wrap">
					<div className="logo"></div>
					<div className="login-wrap px-5 py-3">
						<div className="d-flex">
							<h3 className="w-100 mb-4 text-center text-capitalize">Back office [{currentServerMode}]</h3>
						</div>
						{
							signInError &&
							<div className="d-flex">
								<span className="text-danger">{signInError.message}</span>
							</div>
						}
						<form onSubmit={handleSubmit} className="signin-form">
							<div className="form-group mt-3">
								<label className="form-control-placeholder" htmlFor="username">Email</label>
								<input type="email" placeholder="Email" className="form-control"
									required
									defaultValue={signInData.email}
									onChange={(e) => {
										setSignInData(state => {
											state.email = e.target.value;
											return state;
										});
									}} />
							</div>
							<div className="form-group">
								<label className="form-control-placeholder" htmlFor="password">Password</label>
								<div className="position-relative">
									<input id="password-field" type={isShowPass ? "text" : "password"}
										className="form-control"
										required
										placeholder="Password"
										defaultValue={signInData.password}
										onChange={(e) => {
											setSignInData(state => {
												state.password = e.target.value;
												return state;
											});
										}} />
									<FontAwesomeIcon icon={(isShowPass ? "eye-slash" : "eye")}
										className={"fa-fw field-icon toggle-password"}
										onClick={handleTogglePassword} />
								</div>
							</div>
							<div className="form-group">
								<button type="submit" className="form-control btn btn-primary rounded submit px-3">Sign In</button>
							</div>
							<div className="form-group d-md-flex">
								<div className="w-100 text-end">
									<Link to="/forgot-password">Forgot Password?</Link>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

SignIn.displayName = "SignIn";

export default React.memo(SignIn);