import "./SearchBar.scss";
import { useDebounce } from 'usehooks-ts';
import React, { useEffect, useState } from 'react';

export type SearchBarProps = {
	defaultKeyword?: string;
	maxLength?: number;
	title: string;
	onKeywordChange: (value: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onKeywordChange, defaultKeyword, title, maxLength }) => {
	const [keyword, setKeyword] = useState(defaultKeyword || "");
	useEffect(() => {
		setKeyword(defaultKeyword || "");
	}, [defaultKeyword]);

	const debouncedKeyword = useDebounce<string>(keyword, 500);

	useEffect(() => {
		onKeywordChange(debouncedKeyword);

		return () => { };
	}, [debouncedKeyword]);

	return (
		<div className="search-bar w-100">
			<input type="search" value={keyword} maxLength={maxLength || 100} onChange={e => setKeyword(e.target.value)} className="form-control" placeholder={title} />
		</div>
	);
}

SearchBar.displayName = 'SearchBar';

export default React.memo(SearchBar);