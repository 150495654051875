import './ChangePasswordForm.scss';
import React, { useRef } from 'react';
import { useToggle } from 'usehooks-ts';
import { ChangePasswordType } from '../../models';
import { Card, CardBody, CardFooter, Form, FormGroup, Input, Label } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ChangePasswordFormProps = {
	isUpdating: boolean;
	onPasswordSubmit: (e: ChangePasswordType) => void;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ isUpdating, onPasswordSubmit }) => {
	const [isShowPass, toggleShowPass] = useToggle(false);
	const [isShowNewPass, toggleShowNewPass] = useToggle(false);
	const refChangePasswordModel = useRef<ChangePasswordType>({} as ChangePasswordType);

	return (
		<div className="change-password-form">
			<Form onSubmit={(e) => {
				e.preventDefault();
				return onPasswordSubmit(refChangePasswordModel.current);
			}}>
				<Card>
					<CardBody>
						<div className="row">
							<div className="col-12 position-relative">
								<FormGroup floating>
									<Input
										name="password"
										type={isShowPass ? "text" : "password"}
										required
										onChange={e => refChangePasswordModel.current.password = e.target.value}
									/>
									<Label for="password">
										Current Password
									</Label>
								</FormGroup>
								<FontAwesomeIcon icon={isShowPass ? "eye-slash" : "eye"}
									className={"fa-fw field-icon toggle-password"}
									onClick={toggleShowPass} />
							</div>
						</div>
						<div className="row">
							<div className="col-12 position-relative">
								<FormGroup floating>
									<Input
										name="newPassword"
										type={isShowNewPass ? "text" : "password"}
										required
										minLength={8}
										onChange={e => refChangePasswordModel.current.newPassword = e.target.value}
									/>
									<Label for="newPassword">
										New password
									</Label>
								</FormGroup>
								<FontAwesomeIcon icon={isShowNewPass ? "eye-slash" : "eye"}
									className={"fa-fw field-icon toggle-password"}
									onClick={toggleShowNewPass} />
							</div>
						</div>
					</CardBody>
					<CardFooter>
						<div className="row">
							<div className="col-12 text-center">
								<button type="submit" className="btn btn-primary" disabled={isUpdating}><FontAwesomeIcon icon="save" /> Change</button>
							</div>
						</div>
					</CardFooter>
				</Card>
			</Form>
		</div>
	);
}

ChangePasswordForm.displayName = 'ChangePasswordForm';

export default React.memo(ChangePasswordForm);