import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SystemTraceResultModel } from './SystemTraceFilterModel';
import moment from 'moment';

export type PurgeCacheItemModalProps = {
	item: SystemTraceResultModel,
	onClose: () => void
}

const SystemTraceItemModal: React.FC<PurgeCacheItemModalProps> = ({ item, onClose }) => {
	return (
		<div className="purge-cache-item-modal">
			<Modal isOpen={true} size="lg">
				<ModalHeader toggle={onClose}>{item.module} - {item.type}</ModalHeader>
				<ModalBody>
						<p>
							<i>{moment(item.createdTime).format("yyyy-MM-DD hh:mm:ss")}</i><br />
							<b>{item.source}</b>
						</p>
						<p>
							<span>{item.message}</span>						
						</p>
					<p>
					Data:
					</p>
					<pre className="cache-data">{item.data}</pre>
					<p>Trace:</p>
					<pre className="cache-data">{item.stackTrace}</pre>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={onClose}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

SystemTraceItemModal.displayName = 'SystemTraceItemModal';

export default React.memo(SystemTraceItemModal);