import React from 'react';

import './User.scss';

const User: React.FC = () => {
    return (
        <div className="user">User</div>
    );
}

User.displayName = 'User';

export default React.memo(User);