import React, { ReactNode } from "react";

import "./CheckboxToggle.scss";

export type CheckboxToggleProps = {
	value: boolean;
	onChange?: (value: boolean) => void;
	isUpdating?: boolean;
	name?: string;
	title?: string;
	uncheckedLabel?: string | ReactNode;
	checkedLabel?: string | ReactNode;
}

const CheckboxToggle: React.FC<CheckboxToggleProps> = ({ name, value, onChange, title, uncheckedLabel, checkedLabel, isUpdating }) => {
	return (
		<div className="checkbox-toggle" title={title}>
			<input id="control-valid" type="checkbox"
				name={name || "toggleCheckBox"}
				checked={value || false}
				onChange={(e) => {
					if (!onChange || typeof onChange != "function" || isUpdating) {
						return;
					}

					onChange(e.target.checked);
				}} />
			{uncheckedLabel != undefined ? uncheckedLabel : <span>No</span>}
			{checkedLabel != undefined ? checkedLabel : <span className={value ? "text-bg-success" : ""}>{checkedLabel || "Yes"}</span>}
		</div>
	);
}

CheckboxToggle.displayName = "CheckboxToggle";

export default React.memo(CheckboxToggle);