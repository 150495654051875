import { GameSummary, GameInfo, ListResult, ListGameQuery } from "../models";
import { getData, postData } from "./axios/ConfigAxios";

export async function getGameNames() {
	return await getData<ListResult<GameSummary>>("api/game-info/names",  {});
}

export async function getGames(query: ListGameQuery) {
	return await getData<ListResult<GameSummary>>("api/game-info",  query);
}

export async function getGameDetail(id: string) {
	return await getData<GameInfo>(`api/game-info/${id}`);
}

export async function getPlayingGames() {
	return await getData<ListResult<any>>("/api/game-info/playing-games");
}

export async function addGame(data: GameInfo) {
	return await postData<GameInfo>("api/game-update/add", data);
}
export async function updateGame(data: GameInfo) {
	return await postData<GameInfo>("api/game-update/update", data);
}

export async function updateGameActiveState(id: string, isActive: boolean) {
	return await postData<boolean>("api/game-update/active", { id, isActive });
}

export async function updateGameFeaturedState(id: string, isFeature: boolean) {
	return await postData<boolean>("api/game-update/feature", { id, isFeature });
}
