import './BettingTransactionHistory.scss';
import React, { useRef, useState } from 'react';
import {
	BettingTransactionHistoryFilter,
	BettingTransactionHistoryResult,
	BettingTransactionHistoryResultSummary,
	BettingTransactionHistoryFilterModel,
    BettingTransactionHistoryResultModel
} from '../../components/BettingTransactionHistory';
import { useSearchParams } from 'react-router-dom';
import { usePageStore } from '../../stores';
import { useEffectOnce } from 'usehooks-ts';
import { ListResult } from '../../models';
import { getBetHistory } from '../../services';

const BettingTransactionHistory: React.FC = () => {
	const { setPage } = usePageStore();
	const [searchParams, setSearchParams] = useSearchParams();
	const [result, setResult] = useState<ListResult<BettingTransactionHistoryResultModel>>();
	const defaultFilter = useRef<BettingTransactionHistoryFilterModel>();

	useEffectOnce(() => {
		setPage({ title: "Betting transaction history", pagePath: ["History", "Betting"] });
		defaultFilter.current = Object.fromEntries(searchParams) as BettingTransactionHistoryFilterModel;
		getData(searchParams);
	});

	const filterChangeHandle = (filter) => {
		setSearchParams((state) => {			
			for (let key in filter) {
				state.set(key, filter[key]);
			}

			state.set("page", "1");
			getData(state);
			return state;
		});
	}

	const searchLinkChangeHandle = (filter: any) => {
		setSearchParams((state) => {			
			for (let key in filter) {
				state.set(key, filter[key]);
			}

			state.set("page", "1");
			defaultFilter.current = Object.fromEntries(searchParams) as BettingTransactionHistoryFilterModel;
			getData(state);
			return state;
		});
	}

	const pageChangeHandle = function (page: number): void {
		setSearchParams((state) => {
			state.set("page", page.toString());
			getData(state);			
			return state;
		});
	}

	const pageSizeChangeHandle = (pageSize: number) => {
		setSearchParams((state) => {
			state.set("pageSize", pageSize.toString());
			getData(state);		
			return state;
		});
	}

	const getData = (data) => {
		let query = Object.fromEntries(data) as BettingTransactionHistoryFilterModel;

		if (Object.keys(query).length == 0) {
			return;
		}

		getBetHistory(query).then(([list]) => {
			setResult({data: list?.data ?? [], total: list?.total ?? 0})
		});
	}

	return (
		<div className="betting-transaction-history">
			<BettingTransactionHistoryFilter 
			defaultFilter={defaultFilter.current} 
			onFilterChange={filterChangeHandle} />
			<BettingTransactionHistoryResultSummary />
			<BettingTransactionHistoryResult result={result}
				defaultPage={searchParams.get("page")}
				searchByCol={searchLinkChangeHandle}
				pageSizeChangeHandle={pageSizeChangeHandle}
				pageChangeHandle={pageChangeHandle} />
		</div>
	);
}

BettingTransactionHistory.displayName = 'BettingTransactionHistory';

export default React.memo(BettingTransactionHistory);