import "./OnlinePlayersWidget.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getTotalPlayerOnline } from "../../../services";
import { Card } from "reactstrap";
import { NavLink } from "react-router-dom";
import { useUserStore } from "../../../stores";

export type OnlinePlayersWidgetProps = {
	showTotalOnly?: boolean;
	className?: string;
}

const OnlinePlayersWidget: React.FC<OnlinePlayersWidgetProps> = ({className, showTotalOnly}) => {
	const [totalPlayer, setTotalPlayer] = useState<any>({ count: 0, lastUpdate: new Date() });
	const { user } = useUserStore();

	const { isLoading, data, dataUpdatedAt } = useQuery( { 
			queryKey: ["getTotalPlayerOnline"], 
			queryFn: getTotalPlayerOnline,
			refetchOnWindowFocus: true,
			refetchInterval: () => { return user ? 30000 : false; }
		});

	useEffect(() => {
		if (!data) {
			return;
		}

		setTotalPlayer({ count: data[0], lastUpdate: new Date(dataUpdatedAt)});
	}, [data]);

	if (showTotalOnly) {
		return (
			<div className="widget online-players-widget text-white">
				{ !isLoading ? <div className="" title={`Last update: ${totalPlayer.lastUpdate.toLocaleString()}`}>Playing: <b>{totalPlayer.count || 0}</b> <FontAwesomeIcon icon="user"  className={totalPlayer.count > 0 ? "text-success" : ""} /></div> : ""}
			</div>
		);
	}

	return (
		<div className="widget online-players-widget">
			<Card className={className}>
				<div className="card-header "><FontAwesomeIcon icon="user" className={totalPlayer.count >= 0 ? "text-success" : ""}/> Total online player</div>
				<div className="card-body d-flex flex-column" title="Refresh in 30s">
					{ !isLoading ? <div className=" mb-1"><b>{totalPlayer.count || 0}</b> is playing games.</div> : ""}
					<small>Last update: {totalPlayer.lastUpdate.toLocaleString()}</small>
				</div>
				<div className="card-footer d-flex align-items-center justify-content-end">
					<NavLink className="small" to="/online-players">View Details</NavLink>
					<div className="small"><FontAwesomeIcon icon={"angle-right"}/></div>
				</div>
			</Card>
		</div>
	);
}

OnlinePlayersWidget.displayName = "OnlinePlayersWidget";

export default memo(OnlinePlayersWidget);