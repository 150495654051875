import './DateRangePicker.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnClickOutside } from 'usehooks-ts';

export type DateRangePickerInputProps = {
	label: string;
	name: string;
	startDate?: string | null;
	endDate?: string | null;
	onChange: (value: DateRangeModel) => void;	
}

export type DateRangeModel = {
	key?: string;
	endDate: string;
	startDate: string;
}

const DateRangePickerInput: React.FC<DateRangePickerInputProps> = ({ label, name, onChange, startDate, endDate }) => {
	const [range, setRange] = useState<any>({});
	const [isOpenPicker, setIsOpenPicker] = useState<boolean>(false);
	  const ref = useRef(null);

	  useOnClickOutside(ref, () => {
		  setIsOpenPicker(false);
	  });

	useEffect(() => {
		setRange({ startDate: startDate ? moment(startDate, "yyyy-MM-DD").toDate() : new Date(), endDate: endDate ? moment(endDate, "yyyy-MM-DD").toDate() : new Date() });
	}, [startDate, endDate]);

	return (
		<div className="date-range-picker" ref={ref}>
			<div className="input-box position-relative">
				<FormGroup floating>
					<input
						name={name}
						type="text"
						readOnly
						className="form-control"
						title="Toggle date picker"
						value={`${startDate} - ${endDate}`}
					/>
					<label className="form-label">{label}</label>
				</FormGroup>
				{
					isOpenPicker ? 
				<FontAwesomeIcon className="picker-toggle open" title="Close" onClick={() => { setIsOpenPicker(false) }} icon="calendar-minus" />	:
				<FontAwesomeIcon className="picker-toggle" title="Open" onClick={() => { setIsOpenPicker(true) }} icon="calendar-plus" />
			}
			</div>
			{
				!isOpenPicker ? null :
					<DateRangePicker
						className="picker"
						onChange={(value) => {
							const data = { 
								startDate: moment(value.range1.startDate).format("yyyy-MM-DD"), 
								endDate: moment(value.range1.endDate).format("yyyy-MM-DD") 
							} as DateRangeModel;
							onChange(data);
						}}
						ranges={[range]}
						dateDisplayFormat="yyyy-MM-dd"
						label={label}
						startDatePlaceholder="From"
						endDatePlaceholder="To" />
			}
		</div>
	);
}

DateRangePickerInput.displayName = 'DateRangePickerInput';

export default React.memo(DateRangePickerInput);