import "./AddUser.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Label } from "reactstrap";
import { useEffectOnce, useToggle } from "usehooks-ts";
import { UserInfoType } from "../../models";
import { addUser } from "../../services";
import { Role } from '../../layouts/routing/Role';
import { usePageStore } from "../../stores";

export type AddUserProps = {
}

const AddUser: React.FC<AddUserProps> = () => {
	const [isUpdating, toggleUpdating] = useToggle();
	const [user, setUser] = useState<UserInfoType>({} as UserInfoType);
	const navigate = useNavigate();
	const { setPage } = usePageStore();

	useEffectOnce(() => {
		setPage({ title: "List user", pagePath: ["List user", "Add user"] });
	});

	const formSubmitHandle = useCallback((e) => {
		e.preventDefault();
		toggleUpdating();

		user.roleName = user.role.toString();
		addUser(user).then(([result, error]) => {
			toggleUpdating();

			if (result) {
				navigate("/users");
			}
		});
	}, [user]);

	function getEnumKeys<
		T extends string,
		TEnumValue extends string | number,
	>(enumVariable: { [key in T]: TEnumValue }) {
		return Object.keys(enumVariable).filter((v) => isNaN(Number(v))) as Array<T>;
	}

	return (
		<div className="add-user">
			<Form onSubmit={formSubmitHandle}>
			<div className="row">
				<div className="col-md-6 mx-auto">
				<Card className="card mb-4">
					<CardHeader>
						<div className="row">
							<div className="col-md-2 ms-auto d-flex">
								<Link className="btn btn-secondary btn-small ms-auto" color="secondary" to="/users">Back</Link>
							</div>
						</div>
					</CardHeader>
					<CardBody>
						<div className="row">
							<div className="col-12">
								<FormGroup floating>
									<Input
										name="userEmail"
										placeholder="Emaill"
										type="email"
										required
										maxLength={100}
										onChange={e => setUser({ ...user, userEmail: e.target.value })}
									/>
									<Label for="userEmail">
										Emaill
									</Label>
								</FormGroup>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<FormGroup floating>
									<Input
										name="userName"
										placeholder="Name"
										type="text"
										required
										maxLength={100}
										onChange={e => setUser({ ...user, userName: e.target.value })}
									/>
									<Label for="userName">
										Name
									</Label>
								</FormGroup>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<FormGroup floating>
									<Input
										type="select"
										name="role"
										placeholder="Role"
										required
										onChange={e => setUser({ ...user, role: Role[e.target.value] })}
									>
												{getEnumKeys(Role).map((key, index) => (
													<option key={key} value={Role[key]}>
														{key}
													</option>
												))}
									</Input>
									<Label for="role">
										Role
									</Label>
								</FormGroup>
							</div>
						</div>
					</CardBody>
					<CardFooter>
						<div className="row">
							<div className="col-12 text-center">
								<button type="submit" className="btn btn-primary" disabled={isUpdating}><FontAwesomeIcon icon="save" /> Add</button>
							</div>
						</div>
					</CardFooter>
				</Card>				
				</div>
			</div>
			</Form>
		</div>
	);
}

AddUser.displayName = "AddUser";

export default React.memo(AddUser);