import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export type PageStore = {
    currentServerMode?: string;
    currentPage: PageNavModel;
    setServerMode: (data?: string) => void;
    setPage: (data: PageNavModel) => void;
    pageLoadingProgress: number;
    setPageLoadProgress: (value: number) => void;
}

export type PageNavModel = {
    title: string;
    pagePath: string[];
}

export const usePageStore = create<PageStore>()(
    immer((set) => ({
        currentServerMode: "",
        currentPage: { title: "", pagePath: [""] } as PageNavModel,
        setServerMode(data?: string) {
            set(state => {
                state.currentServerMode = data;
            });
        },
        setPage(data: PageNavModel) {
            set((state: PageStore) => {
                state.currentPage = data;
            });
        },
        pageLoadingProgress: 0,
        setPageLoadProgress(value: number) {
            set((state: PageStore) => {
                if (value < 0) {
                    value = 0;
                }

                if (value > 100) {
                    value = 100;
                }

                state.pageLoadingProgress = value;
            });
        }
    }))
);