import './BettingTransactionHistoryFilter.scss';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, FormGroup, Input, Label } from 'reactstrap';
import { DateRangePickerInput } from '../DateRangePicker';
import { toast } from 'react-toastify';
import { GameSummary, CasinoModel } from '../../models';
import { useEffectOnce } from 'usehooks-ts';
import { getCasinoNames, getGameNames } from '../../services';
import { BettingTransactionHistoryFilterModel } from './BettingTransactionHistoryFilterModel';

type BettingTransactionHistoryFilterProps = {
	defaultFilter?: BettingTransactionHistoryFilterModel;
	onFilterChange: (filter: BettingTransactionHistoryFilterModel) => void;
}

const BettingTransactionHistoryFilter: React.FC<BettingTransactionHistoryFilterProps> = ({ onFilterChange, defaultFilter }) => {
	const [gameListOption, setGameListOption] = useState<GameSummary[]>([]);
	const [casinoListOption, setCasinoListOption] = useState<CasinoModel[]>([]);
	const [filter, setFilter] = useState<BettingTransactionHistoryFilterModel>(defaultFilter ?? {} as BettingTransactionHistoryFilterModel);

	useEffectOnce(() => {
		getGameNames().then(([result]) => {
			if (result)
				setGameListOption(result.data)
		});

		getCasinoNames().then(([result]) => {
			if (result) {
				setCasinoListOption(result.data)
			}
		});
	});

	useEffect(() => {
		setFilter(defaultFilter || {} as BettingTransactionHistoryFilterModel);
	}, [defaultFilter])

	const searchClickHandler = (e) => {
		e.preventDefault();

		//if (!filter.gameId) {
		//	toast("Select a game", { type: "warning" });
		//	return;
		//}

		if (!filter.fromDate || !filter.toDate) {
			toast("Select a date range", { type: "warning" });
			return;
		}

		onFilterChange(filter);
	}

	const resetClickHandler = (e) => {
		e.preventDefault();

		setFilter(() => {
			const data = {} as BettingTransactionHistoryFilterModel;
			onFilterChange(data);
			return data;
		});
	}

	return (
		<div className="betting-transaction-history-filter">
			<Card className="card mb-4">
				<CardHeader><b>Filter</b></CardHeader>
				<CardBody>
					<div className="row">
						<div className="col-md-3">
							<FormGroup floating>
								<Input
									type="select"
									name="gameId"
									placeholder="Game"
									required
									value={filter.gameId || ""}
									onChange={e => setFilter({ ...filter, gameId: e.target.value })}
								>
									<option value="">--</option>
									{
										!gameListOption ? null :
											gameListOption.map((item: GameSummary) => <option key={item.id} value={item.id}>{item.name}</option>)
									}
								</Input>
								<Label for="gameId">
									Game (*)
								</Label>
							</FormGroup>
						</div>
						<div className="col-md-3 position-relative">
							<DateRangePickerInput label="Period (*)"
								name="dayPeriod"
								startDate={filter.fromDate || ""}
								endDate={filter.toDate || ""}
								onChange={(value) => {
									setFilter({ ...filter, fromDate: value.startDate, toDate: value.endDate });
								}} />
						</div>
						<div className="col-md-3">
							<FormGroup floating>
								<Input
									type="select"
									name="casino"
									placeholder="Casino"
									value={filter.casino || ""}
									onChange={e => setFilter({ ...filter, casino: e.target.value })}
								>
									<option value="">--</option>
									{
										!casinoListOption ? null :
											casinoListOption.map((item: CasinoModel) => <option key={item.id} value={item.id}>{item.name}</option>)
									}
								</Input>
								<Label for="casino">
									Casino
								</Label>
							</FormGroup>
						</div>
						<div className="col-md-3">
							<FormGroup floating>
								<Input
									type="select"
									name="isWin"
									placeholder="Status"
									value={filter.isWin || ""}
									onChange={e => setFilter({ ...filter, isWin: e.target.value })}
								>
									<option value="">All</option>
									<option value="1">Win</option>
									<option value="0">Lose</option>
								</Input>
								<Label for="status">
									Status
								</Label>
							</FormGroup>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<FormGroup floating>
								<input type="text"
									placeholder="Game session id"
									maxLength={50}
									value={filter.sessionId || ""}
									required
									onChange={e => setFilter({ ...filter, sessionId: e.target.value })}
									name="sessionId"
									className="form-control"
								/>
								<Label className="form-label" htmlFor="sessionId">Game Session</Label>
							</FormGroup>
						</div>
						<div className="col-md-3">
							<FormGroup floating>
								<input type="text"
									placeholder="Transaction Id"
									maxLength={50}
									value={filter.transactionId || ""}
									required
									onChange={e => setFilter({ ...filter, transactionId: e.target.value })}
									name="transactionId"
									className="form-control"
								/>
								<Label className="form-label" htmlFor="sessionId">Transaction</Label>
							</FormGroup>
						</div>
						<div className="col-md-3">
							<FormGroup floating>
								<input type="text"
									placeholder="Player Id"
									maxLength={50}
									value={filter.playerId || ""}
									required
									onChange={e => setFilter({ ...filter, playerId: e.target.value })}
									name="playerId"
									className="form-control"
								/>
								<Label className="form-label" htmlFor="playerId">Player</Label>
							</FormGroup>
						</div>
						<div className="col-md-3">
						</div>
					</div>
				</CardBody>
				<CardFooter>
					<div className="d-flex">
						<div className="ms-auto">
							<button className="btn btn-secondary" type="reset" onClick={resetClickHandler}>Clear</button>
							<button className="btn btn-primary ms-1" type="button" onClick={searchClickHandler}>Search</button>
						</div>
					</div>
				</CardFooter>
			</Card>
		</div>
	);
}

BettingTransactionHistoryFilter.displayName = 'BettingTransactionHistoryFilter';

export default React.memo(BettingTransactionHistoryFilter);