import React from 'react';
import { Typeahead, TypeaheadComponentProps } from 'react-bootstrap-typeahead';
import './DropdownCombobox.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';

export type DropdownComboboxProps = {
} & TypeaheadComponentProps;

const DropdownCombobox: React.FC<DropdownComboboxProps> = (props) => {
	return (
		<div className="dropdown-combobox w-100">
			<Typeahead {...props} />
		</div>
	);
}

DropdownCombobox.displayName = 'DropdownCombobox';

export default React.memo(DropdownCombobox);