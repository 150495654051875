import './EditUserModal.scss';
import React, { useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { UserInfoType } from '../../models';
import { getUser } from '../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Role } from '../../layouts/routing/Role';
import { useEffectOnce } from 'usehooks-ts';

export type EditUserModalProps = {
	id: number;
	userEmail: string;
	isSaving: boolean;
	onSubmit: (data: any) => void
	onClose: () => void
}

const EditUserModal: React.FC<EditUserModalProps> = ({ id, userEmail, onClose, isSaving, onSubmit }) => {
	const [user, setUser] = useState<UserInfoType>({} as UserInfoType);

	useEffectOnce(() => {
		getUser(id).then(([res]) => {
			if (res) {
				res.role = Role[res.roleName];
				setUser(res);
			}
		})
	});

	const submitHandle = () => {
		onSubmit({ userEmail: user.userEmail, id: user.id, userName: user.userName.trim(), role: user.role });
	}

	const roleHandle = (event) => {
		setUser({ ...user, role: event.target.value, roleName: Role[event.target.value] });
	}

	function getEnumKeys<
		T extends string,
		TEnumValue extends string | number,
		>(enumVariable: { [key in T]: TEnumValue }) {
		return Object.keys(enumVariable).filter((v) => isNaN(Number(v))) as Array<T>;
	}

	return (
		<div className="edit-user-modal">
			<Modal isOpen={true} size="lg">
				<ModalHeader toggle={onClose}>Edit {userEmail}</ModalHeader>
				{
					!user ?
						<FontAwesomeIcon icon="spinner" className="fa-spin" /> :
						<React.Fragment>
							<ModalBody>
								<div className="row">
									<div className="col-12">
										<FormGroup floating>
											<Input
												name="name"
												placeholder="Name"
												type="text"
												required
												value={user.userName || ''}
												maxLength={100}
												onChange={e => setUser({ ...user, userName: e.target.value })}
											/>
											<Label for="name">
												Name
											</Label>
										</FormGroup>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<FormGroup floating>
											<Input
												type="select"
												name="role"
												placeholder="Role"
												required
												value={user.role}
												onChange={roleHandle}
											>
												{getEnumKeys(Role).map((key, index) => (
													<option key={key} value={Role[key]}>
														{key}
													</option>
												))}
											</Input>
											<Label for="role">
												Role
											</Label>
										</FormGroup>
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<Button color="warning" type="button" disabled={!user.userName || !user.userEmail || isSaving} onClick={submitHandle}>
									<span>Submit</span> {isSaving && <FontAwesomeIcon icon="spinner" className="fa-spin" />}
								</Button>{' '}
								<Button color="secondary" onClick={onClose}>
									Close
								</Button>
							</ModalFooter>
						</React.Fragment>
				}
			</Modal>
		</div >
	);
}

EditUserModal.displayName = 'EditUserModal';

export default React.memo(EditUserModal);