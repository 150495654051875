import React from 'react';

import './BettingTransactionHistoryResultSummary.scss';

export type BettingTransactionHistoryResultSummaryProps = {

}

const BettingTransactionHistoryResultSummary: React.FC<BettingTransactionHistoryResultSummaryProps> = () => {
    return (
        <div className="betting-transaction-history-result-summary"></div>
    );
}

BettingTransactionHistoryResultSummary.displayName = 'BettingTransactionHistoryResultSummary';

export default React.memo(BettingTransactionHistoryResultSummary);