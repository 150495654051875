import React, { useCallback } from 'react';

import './ChangePassword.scss';
import { ChangePasswordForm } from '../../components';
import { usePageStore, useUserStore } from '../../stores';
import { useEffectOnce, useToggle } from 'usehooks-ts';
import { changePassword } from '../../services';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ChangePassword: React.FC = () => {
	const { setPage } = usePageStore();
	const { signOut } = useUserStore();
	const [isUpdating, toggleUpdating] = useToggle();
	const navigate = useNavigate(); 

	useEffectOnce(() => {
		setPage({ title: "Change Password", pagePath: ["Profile", "Change Password"] });
	});

	const passwordSubmitHandler = useCallback(async (e) => {
		toggleUpdating();
		let [result] = await changePassword(e); 

		if (result) {
			toast("Password is updated.", { autoClose: false, type: "success" });
			await signOut();
			navigate("/sign-in");
		}
	}, [])

	return (
		<div className="change-password">
			<div className="row mt-3 justify-content-center">
				<div className="col-md-4">
					<ChangePasswordForm isUpdating={isUpdating} onPasswordSubmit={passwordSubmitHandler} />
				</div>
			</div>
		</div>
	);
}

ChangePassword.displayName = 'ChangePassword';

export default React.memo(ChangePassword);