import './AddGame.scss';
import React, { useCallback } from 'react';
import { AddEditGameForm } from '../../components';
import { GameInfo } from '../../models';
import { addGame } from '../../services';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEffectOnce } from 'usehooks-ts';
import { usePageStore } from '../../stores';

export type AddGameProps = {
}

const AddGame: React.FC<AddGameProps> = () => {
	const navigate = useNavigate();
	const { setPage } = usePageStore();

	useEffectOnce(() => {
		setPage({ title: "Game Manage", pagePath: ["Add Game"] });
	});

	const submitHandle = useCallback((game: GameInfo) => {
		addGame(game).then(([result, error]) => {
			if (error?.code !== 200) {
				toast(`Error Add Game ${game.name}: ${error?.message}.`, { type: "error" });
				return;
			}

			toast(`Game ${game.name} is added.`, { type: "success" });
			navigate("/games");
		});
	}, []);

	return (
		<div className="add-game">
			<AddEditGameForm onSubmit={submitHandle} />
		</div>
	);
}

AddGame.displayName = 'AddGame';

export default React.memo(AddGame);