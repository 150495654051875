
const Error404 = () => {
  return (
    <>
      <h1 >Page Not Found</h1>
      <div className='mb-15'>
        The page you looked not found! <br />
      </div>
    </>
  )
}

export {Error404}
