import React from "react";
import { Link } from "react-router-dom";
import { SearchBar } from "../../components/SearchBar";
import { GameSummary } from "../../models";
import { Table, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { parseISO } from "date-fns"
import { BadgeTagList, PagingPanel, CheckboxToggle } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useListGame } from "./useListGame";
import { useUserStore } from "../../stores";

const ListGame: React.FC = () => {
	const {
		searchParams,
		isLoading,
		gameListResult,
		isStateUpdating,
		getOrderDirection,
		sortHandle,
		searchChangeHandle,
		//gameActiveChangeHandle,
		//gameFeaturedChangeHandle,
		pageChangeHandle,
		pageSizeChangeHandle
	} = useListGame();

	const { user } = useUserStore();

	return (
		<div className="list-game">
			<Card className="card mb-4">
				<CardHeader>
					<div className="row">
						<div className="col-md-3">
							<SearchBar
								onKeywordChange={searchChangeHandle}
								defaultKeyword={searchParams.get("keyword") ?? ""}
								title="Find game" />
						</div>
						<div className="col-md-2 ms-auto text-md-end">
							<Link className="btn btn-primary" color="primary" to="/games/add">Add new</Link>
						</div>
					</div>
				</CardHeader>
				<CardBody className="table-responsive">
					{
						isLoading && <p className="text-center">
							<FontAwesomeIcon icon="spinner" className="fa-spin" />
						</p>
					}
					<Table className="" hover>
						<thead>
							<tr className="text-capitalize">
								<th>
									<span>Id</span>
								</th>
								<th>
									<span>Name</span>
								</th>
								{/*<th onClick={sortHandle} data-col-name="tags" className={getOrderDirection("tags")}>*/}
								{/*	<span className="datatable-sorter">Tag</span>*/}
								{/*</th>*/}
								{/*<th onClick={sortHandle} data-col-name="total_view" className={getOrderDirection("total_view")}>*/}
								{/*	<span className="datatable-sorter">Play count</span>*/}
								{/*</th>*/}
								{/*<th onClick={sortHandle} data-col-name="last_played" className={getOrderDirection("last_played")}>*/}
								{/*	<span className="datatable-sorter">Last played</span>*/}
								{/*</th>*/}
								<th>
									Country
								</th>
								{/*<th className="text-center">Featured</th>*/}
								<th className="text-center">Status</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{
								gameListResult?.data && gameListResult.data.length > 0
								&& gameListResult.data.map((item: GameSummary) => {
									return (
										<tr key={item.id}>
											<th scope="row"><Link title="View detail" to={`/games/edit/${item.id}`}>{item.id}</Link></th>
											<td>{item.name}</td>
									{/*		<td><BadgeTagList tags={item.tags} /></td>*/}
											{/*<td>{item.totalView}</td>*/}
											{/*<td>{parseISO(item.lastPlayed).toLocaleString()}</td>*/}
											{/*<td>*/}
												{/*{parseISO(item.createdDate).toLocaleString()}*/}
											{/*</td>*/}
											{/*<td>*/}
												{/*<Link to={`/user/${item.updatedByEmail}`}>{item.updatedBy}</Link>*/}
											{/*</td>*/}
											{/*<td>*/}
												{/*{item.updatedDate && (parseISO(item.updatedDate).toLocaleString())}*/}
											{/*</td>*/}
											{/*<td className="text-center">*/}
											{/*	{*/}
											{/*		isStateUpdating == item.id &&*/}
											{/*		<FontAwesomeIcon icon="spinner" className="fa-spin" />*/}
											{/*	}*/}
											{/*	{*/}
											{/*		isStateUpdating != item.id &&*/}
											{/*		<CheckboxToggle*/}
											{/*			onChange={(value: boolean) => gameFeaturedChangeHandle(item.id, value)}*/}
											{/*			isUpdating={isStateUpdating != undefined}*/}
											{/*			value={item.isFeature}*/}
											{/*			title={`${item.isFeature ? "Pull down from website." : "Show on website."}`}*/}
											{/*		/>*/}
											{/*	}*/}
											{/*</td>*/}
											{/*<td className="text-center">*/}
											{/*	{*/}
											{/*		isStateUpdating == item.id &&*/}
											{/*		<FontAwesomeIcon icon="spinner" className="fa-spin" />*/}
											{/*	}*/}
											{/*	{*/}
											{/*		isStateUpdating != item.id &&*/}
											{/*		<CheckboxToggle*/}
											{/*			onChange={(value: boolean) => gameActiveChangeHandle(item.id, value)}*/}
											{/*			isUpdating={isStateUpdating != undefined}*/}
											{/*			value={item.isActive}*/}
											{/*			title={`Set ${item.name} game ${item.isActive ? " hidden." : " active."}`}*/}
											{/*		/>*/}
											{/*	}*/}
											{/*</td>*/}
											<td>{item.country}</td>
											<td>{ item.status }</td>
											<td>
												<a href={`${item.gameUrl}`}
													rel="noreferrer"
													target="_blank"
													title="Open game">
													<FontAwesomeIcon icon="external-link" className="me-2" />
												</a>
											</td>
										</tr>
									);
								})}
						</tbody>
					</Table>
				</CardBody>
				<CardFooter>
					{
						gameListResult &&
						<PagingPanel total={gameListResult.total}
							pageSize={searchParams.get("pageSize")}
							page={searchParams.get("page")}
							onChange={pageChangeHandle}
							onPageSizeChange={pageSizeChangeHandle}
						/>}
				</CardFooter>
			</Card>
		</div>
	);
};

export default React.memo(ListGame);