import { SystemTraceFilterModel, SystemTraceResultModel } from "../components/SystemTrace";
import { ListResult } from "../models";
import { getData } from "./axios/ConfigAxios";

export async function getLogs(query: SystemTraceFilterModel) {
	return await getData<ListResult<SystemTraceResultModel>>("/api/system-trace", query);
}

export async function getLogModule() {
	return await getData<string[]>("/api/system-trace/filter-data-module", {});
}

export async function getLogType() {
	return await getData<string[]>("/api/system-trace/filter-data-type", {});
}

export async function getLogSource() {
	return await getData<string[]>("/api/system-trace/filter-data-source", {});
}