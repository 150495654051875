"use client";
import React from 'react';
import './GameSpecs.scss';
import { GameInfo } from '../../models';
import { parseISO } from 'date-fns';
import { BadgeTagList } from '../BadgeTagList';

export type GameSpecsProps = {
	game: GameInfo;
}

const GameSpecs: React.FC<GameSpecsProps> = ({ game }) => {
	return (
		<div className="game-specs">
			<p className="text-2xl font-bold text-amber-400">GAME SPECS</p>

			<ul className="bg-black/25 px-4 py-2 h-full">
				<li className="grid grid-cols-2">
					<p>Name: {game.name || ""}</p>
					<p>GameID: {game.id || ""}</p>
					{/*<p>Tags: <BadgeTagList tags={game.tags} /></p>*/}
					<p>Game Url: {game.gameUrl || ""}</p>
					<p>Client Url: {game.clientUrl || ""}</p>
					<p>History: {game.historyUrl || ""}</p>
					{/*<p>Total Access: {game.totalView || ""}</p>*/}
					{/*<p>Last Played:  {parseISO(game.lastPlayed).toLocaleString()}</p>*/}
					{/*<p>Added Date: {parseISO(game.createdDate).toLocaleString()}</p>*/}
					{/*<p>Enable: {game.isEnabled ? "On" : "Off"}</p>*/}
					<p>Status: {game.status}</p>
				</li>
				<p>Game Config: {game.config || ""}</p>
				<button className="bg-white/25">Delete Game</button>
			</ul>
		</div>
	);
}

GameSpecs.displayName = 'GameSpecs';

export default React.memo(GameSpecs);