/* The following line can be included in a src/App.scss */
import "./assets/css/bootstrap-template.css";
import "./assets/css/main.scss";
import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from "react";
import RoutesList from "./layouts/routing/Routes";
import { usePageStore, useUserStore } from "./stores";
import { BrowserRouter } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { useEffectOnce } from "usehooks-ts";
import { ToastContainer } from 'react-toastify';
import { AxiosInterceptor } from "./services/axios/ConfigAxios";
import { QueryClient, QueryClientProvider, useIsFetching } from "react-query";
import { ProgessBar } from "./components";

library.add(fas, far);
type AppProps = {
	basename: string
}

const queryClient = new QueryClient(({
	defaultOptions: {
		queries: {
			
		},
	},
}) as any);

const App: React.FC<AppProps> = ({ basename }) => {
	const { getUserInfo } = useUserStore();
	const [isChecking, setIsChecking] = useState<boolean | undefined>();
	const { pageLoadingProgress } = usePageStore();

	useEffectOnce(() => {
		if (isChecking) {
			return;
		}

		setIsChecking(true);
		getUserInfo().then((_) => {
			setIsChecking(false);
		});
	});

	if (isChecking || isChecking == undefined) {
		return (<div className="splash"><span>Checking ...</span></div>);
	}

	if (isChecking == false) {
		return (
			<QueryClientProvider client={queryClient}>
				<BrowserRouter basename={basename}>
					<AxiosInterceptor>
						{
							(pageLoadingProgress > 0 && pageLoadingProgress < 100) ?
								<ProgessBar value={pageLoadingProgress} /> : null
						}
						<RoutesList/>
						<ToastContainer theme="light" autoClose={5000} newestOnTop hideProgressBar={false} pauseOnHover/>
					</AxiosInterceptor>
				</BrowserRouter>
			</QueryClientProvider>
		);
	}

	return null;
}

export { App };