import "./GamesStatistics.scss";
import React, { useCallback, useState } from "react";
import { usePageStore } from "../../stores";
import { useEffectOnce } from "usehooks-ts";
import { Card, CardBody, CardFooter, CardHeader, FormGroup, Input, Label, Table } from 'reactstrap';
import { DateRangePickerInput, PagingPanel } from "../../components";
import { SearchBar } from "../../components/SearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CasinoModel, GameSummary, GamesStatisticsFilterModel, ListGameOpenCountViewModel, ListResult } from "../../models";
import { getCasinoNames, getGameNames, getGameOpenCount } from "../../services";
import { Link, useSearchParams } from "react-router-dom";
import { parseISO } from "date-fns";
import moment from "moment";

const GamesStatistics: React.FC = () => {
	const { setPage } = usePageStore();

	useEffectOnce(() => {
		setPage({ title: "Game Statistics Summary", pagePath: ["Game Statistics Summary"] });
	});

	const [gameListOption, setGameListOption] = useState<GameSummary[]>([]);
	const [casinoListOption, setCasinoListOption] = useState<CasinoModel[]>([]);
	const [filter, setFilter] = useState<GamesStatisticsFilterModel>({} as GamesStatisticsFilterModel);
	const [players, setPlayers] = useState<ListResult<ListGameOpenCountViewModel>>();
	const [searchParams, setSearchParams] = useSearchParams();

	useEffectOnce(() => {
		setPage({ title: "Game", pagePath: ["Open count"] });

		getGameNames().then(([result]) => {
			if (result)
				setGameListOption(result.data)
		});

		getCasinoNames().then(([result]) => {
			if (result) {
				setCasinoListOption(result.data)
			}
		});

		let defaultFilter = { fromDate: moment().format("yyyy-MM-DD"), toDate: moment().format("yyyy-MM-DD") } as GamesStatisticsFilterModel

		if (searchParams != null && searchParams.size > 0)
			defaultFilter = Object.fromEntries(searchParams) as GamesStatisticsFilterModel;

		setFilter(() => {
			filterChangeHandle(defaultFilter);
			return defaultFilter;
		});
	})

	const setQuery = useCallback((key: string, value: string) => {
		searchParams.set(key, value);
		setSearchParams(searchParams);
	}, [searchParams]);

	const searchClickHandler = (e) => {
		e.preventDefault();

		filterChangeHandle(filter);
	}

	const resetClickHandler = (e) => {
		e.preventDefault();

		setFilter(() => {
			const data = {} as GamesStatisticsFilterModel;
			filterChangeHandle(data);
			return data;
		});
	}

	function filterChangeHandle(filter) {
		setSearchParams((state) => {
			for (let key in filter) {
				state.set(key, filter[key]);
			}

			state.set("page", "1");
			getData(state);
			return state;
		});
	}

	const getData = (data: URLSearchParams) => {
		let query;

		if (data != null) {
			query = Object.fromEntries(data) as GamesStatisticsFilterModel;
		}

		getGameOpenCount(query).then(([list]) => {
			setPlayers(list);
		});
	}

		const pageChangeHandle = function (page: number): void {
		setSearchParams((state) => {
			state.set("page", page.toString());
			getData(state);			
			return state;
		});
	}

	const pageSizeChangeHandle = (pageSize: number) => {
		setSearchParams((state) => {
			state.set("pageSize", pageSize.toString());
			getData(state);		
			return state;
		});
	}

	return (
		<div className="games-statistics">
			<div className="betting-transaction-history-filter">
				<Card className="card mb-4">
					<CardHeader><b>Filter</b></CardHeader>
					<CardBody>
						<div className="row">
							<div className="col-md-3">
								<FormGroup floating>
									<Input
										type="select"
										name="gameId"
										placeholder="Game"
										required
										value={filter.gameId || ""}
										onChange={e => setFilter({ ...filter, gameId: e.target.value })}
									>
										<option value="">--</option>
										{
											!gameListOption ? null :
												gameListOption.map((item: GameSummary) => <option key={item.id} value={item.id}>{item.name}</option>)
										}
									</Input>
									<Label for="gameId">
										Game
									</Label>
								</FormGroup>
							</div>
							<div className="col-md-3 position-relative">
								<DateRangePickerInput label="Period"
									name="dayPeriod"
									startDate={filter.fromDate || ""}
									endDate={filter.toDate || ""}
									onChange={(value) => {
										setFilter({ ...filter, fromDate: value.startDate, toDate: value.endDate });
									}} />
							</div>
							<div className="col-md-3">
								<FormGroup floating>
									<Input
										type="select"
										name="casinoId"
										placeholder="Casino"
										value={filter.casinoId || ""}
										onChange={e => setFilter({ ...filter, casinoId: e.target.value })}
									>
										<option value="">--</option>
										{
											!casinoListOption ? null :
												casinoListOption.map((item: CasinoModel) => <option key={item.id} value={item.id}>{item.name}</option>)
										}
									</Input>
									<Label for="casinoId">
										Casino
									</Label>
								</FormGroup>
							</div>
							<div className="col-md-3">
								<FormGroup floating>
									<Input
										type="select"
										name="isDemoMode"
										placeholder="Game Mode"
										value={filter.isDemoMode || ""}
										onChange={e => setFilter({ ...filter, isDemoMode: e.target.value })}
									>
										<option value="">All</option>
										<option value="1">Demo</option>
										<option value="0">Real</option>
									</Input>
									<Label for="status">
										Mode
									</Label>
								</FormGroup>
							</div>
						</div>
						<div className="row">
							<div className="col-md-3">
								<FormGroup floating>
									<input type="text"
										placeholder="Game session id"
										maxLength={50}
										value={filter.sessionId || ""}
										required
										onChange={e => setFilter({ ...filter, sessionId: e.target.value })}
										name="sessionId"
										className="form-control"
									/>
									<Label className="form-label" htmlFor="sessionId">Game Session</Label>
								</FormGroup>
							</div>
							<div className="col-md-3">
								<FormGroup floating>
									<input type="text"
										placeholder="Player Id"
										maxLength={50}
										value={filter.playerId || ""}
										required
										onChange={e => setFilter({ ...filter, playerId: e.target.value })}
										name="playerId"
										className="form-control"
									/>
									<Label className="form-label" htmlFor="playerId">Player</Label>
								</FormGroup>
							</div>							
							<div className="col-md-3">
								<FormGroup floating>
									<input type="text"
										placeholder="Location"
										maxLength={50}
										value={filter.location || ""}
										required
										onChange={e => setFilter({ ...filter, location: e.target.value })}
										name="location"
										className="form-control"
									/>
									<Label className="form-label" htmlFor="location">Location</Label>
								</FormGroup>
							</div>
						</div>
					</CardBody>
					<CardFooter>
						<div className="d-flex">
							<div className="ms-auto">
								<button className="btn btn-secondary" type="reset" onClick={resetClickHandler}>Clear</button>
								<button className="btn btn-primary ms-1" type="button" onClick={searchClickHandler}>Search</button>
							</div>
						</div>
					</CardFooter>
				</Card>
			</div>
			<Card className="card mb-4">
				<CardHeader>
					<div className="row">
						<div className="col-md-3">
							<SearchBar
								onKeywordChange={key => setQuery("keyword", key)}
								defaultKeyword={searchParams.get("keyword") ?? ""}
								title="Find" />
						</div>
					</div>
				</CardHeader>
				<CardBody className="table-responsive">
					<Table className="table-striped" hover>
						<thead>
							<tr className="text-capitalize">
								<th>Session</th>
								<th><span >Game</span></th>
								<th><span >Casino</span></th>
								<th><span >Player</span></th>
								<th><span >Country</span></th>
								<th><span >Joined date</span></th>
								<th style={{ width: 150 }}>Browser Agent</th>
								<th>Mode</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{
								players?.data && players.data.length > 0
								&& players.data.map((item, index) => {
									return (
										<tr key={index}>
											<th>{item.sessionId}</th>
											<td><Link to={"/games/" + item.gameId}>{item.gameId}</Link></td>
											<td>{item.casinoId}</td>
											<td>{item.playerId}</td>
											<td>{item.location} | {item.ipAddress}</td>
											<td>{parseISO(item.openedDate).toLocaleString()}</td>
											<td>{item.browserAgent}</td>
											<td>{item.isDemoMode ? "Demo" : "Real"}</td>
											<td>
												<FontAwesomeIcon icon="file-lines" title="View action logs" />
											</td>
										</tr>
									);
								})}
						</tbody>
					</Table>
				</CardBody>
				<CardFooter>
				{
						players &&
						<PagingPanel total={players.total}
							pageSize={filter.pageSize}
							page={filter.page}
							onChange={pageChangeHandle}
							onPageSizeChange={pageSizeChangeHandle}
						/>
					}
				</CardFooter>
			</Card>
		</div>
	);
}

GamesStatistics.displayName = "GamesStatistics";

export default React.memo(GamesStatistics);