import { ListResult, CasinoModel, CasinoDetailModel } from "../models";
import { getData, postData } from "./axios/ConfigAxios";

export async function getCasinoNames() {
	return await getData<ListResult<CasinoModel>>("api/casino/names");
}

export async function getCasino() {
	return await getData<CasinoDetailModel[]>("api/casino");
}

export async function getCasinoById(id: string) {
	return await getData<CasinoModel>(`api/casino/${id}`);
}

export async function updateCasino(data: CasinoModel) {
	return await postData<CasinoModel>("api/casino/update", data);
}

export async function addCasino(data: CasinoModel) {
	return await postData<CasinoModel>("api/casino/add", data);
}
