import './ProgessBar.scss';
import React from 'react';
import { Progress } from 'reactstrap';

export type ProgessBarProps = {
    value: number;
    color?: string;
}

const ProgessBar: React.FC<ProgessBarProps> = ({value, color}) => {
    return (
        <div className="progress-bar" title={`Loading: ${value}%`}><Progress animated striped color={ color ?? "success"} value={value} /></div>
    );
}

ProgessBar.displayName = 'ProgessBar';

export default React.memo(ProgessBar);