import React from 'react';
import { Error404 } from './Error404';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ErrorsPage: React.FC = () => {
	const navigate = useNavigate();
	const redirectTo = () => {
		navigate('/')
	}

	return (
		<div className='d-flex flex-column flex-root text-white'>
			<div className='d-flex flex-column flex-column-fluid text-center '>
				<div className="mt-3"><Error404 /></div>
				<div className='mt-5 text-center'>
					<button onClick={redirectTo} className='btn btn-lg btn-primary'>
						<FontAwesomeIcon icon="arrow-alt-circle-right" /> To Dashboard
					</button>
				</div>
			</div>
		</div>
	)
}

export { ErrorsPage }