import './PurgeCachedItem.scss';
import React, { useCallback, useState } from 'react';
import { Card, CardBody, CardFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffectOnce, useToggle } from 'usehooks-ts';
import { usePageStore } from '../../stores';
import { deleteCacheByKey, getTempleteCacheKeys } from '../../services';
import { toast } from 'react-toastify';

const PurgeCachedItem: React.FC = () => {
	const [isUpdating, toggleUpdating] = useToggle();
	const { setPage } = usePageStore();
	const [cacheKey, setCacheKey] = useState<string>("");
	const [keys, setKeys] = useState<string[]>();

	useEffectOnce(() => {
		setPage({ title: "Purge Cache Item", pagePath: ["Purge Cache"] });

		getTempleteCacheKeys().then(([data]) => {
			setKeys(data ?? []);
		})
	});

	const formSubmitHandle = useCallback((e) => {
		e.preventDefault();

		if(cacheKey.indexOf("@") >= 0) {
			toast("Replace template value @ with a real value, or remove it to purge all items.", { type: "error"})
			return;
		}

		toggleUpdating();
		deleteCacheByKey(cacheKey).then(([isUpdated]) => {
			toggleUpdating();
			toast(isUpdated ? "Cache item is purged" : "Key not found.", { type: "info"})
		});
	}, [cacheKey]);

	return (
		<div className="purge-cached-item">
			<Form onSubmit={formSubmitHandle}>
				<div className="row">
					<div className="col-md-6 mx-auto">
						<Card className="card mb-4">
							<CardBody>
								<div className="row">
									<div className="col-12">
										<FormGroup floating>
											<Input
												type="select"
												name="keyTemplate"
												placeholder="Key"
												onChange={e => setCacheKey(e.target.value)}
											>
												<option value="">--</option>
												{
													!keys ? null : keys.map(key  => {
														return <option key={key} value={key}>{key}</option>
													})
												}
											</Input>
											<Label for="role">
												Key Template
											</Label>
										</FormGroup>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<FormGroup floating>
											<Input
												name="cacheKey"
												placeholder="Key"
												type="text"
												required
												maxLength={100}
												value={cacheKey}
												onChange={e => setCacheKey(e.target.value)}
											/>
											<Label for="cacheKey">
												Key <span className="text-danger">(*)</span>
											</Label>
										</FormGroup>
									</div>
								</div>
							</CardBody>
							<CardFooter>
								<div className="row">
									<div className="col-12 text-center">
										<button type="submit" className="btn btn-warning" disabled={isUpdating}><FontAwesomeIcon icon="broom" /> Purge</button>
									</div>
								</div>
							</CardFooter>
						</Card>
					</div>
				</div>
			</Form>
		</div>
	);
}

PurgeCachedItem.displayName = 'PurgeCachedItem';

export default React.memo(PurgeCachedItem);