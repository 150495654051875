import axios, { AxiosRequestConfig } from "axios"
import { ApiError, ServerResponseResult } from "../../models";
import { toast } from 'react-toastify';
import { usePageStore, useUserStore } from "../../stores";
import { useNavigate } from "react-router-dom";
import { ReactNode, useEffect, useRef } from "react";

const instanceAxios = axios.create({
	baseURL: '/backoffice'
});

// Error Global Handler
interface AxiosInterceptorProps {
	children: any;
}

export const AxiosInterceptor: React.FC<AxiosInterceptorProps> = ({ children }) => {
	const navigate = useNavigate();
	const { setUserInfo } = useUserStore();
	const { setPageLoadProgress } = usePageStore();
	const progessTimeOutId = useRef<any>();

	useEffect(() => {
		const reqHandle = config => {
			// Do something before request is sent
			setPageLoadProgress(0);

			progessTimeOutId.current = setTimeout(() => {
				setPageLoadProgress(50);
				clearTimeout(progessTimeOutId.current);

				progessTimeOutId.current = setTimeout(() => {
					setPageLoadProgress(80);
					clearTimeout(progessTimeOutId.current);
				}, 20);
			}, 20);
			
			return config;
		}

		const resHandle = response => {
			clearTimeout(progessTimeOutId.current);
			setPageLoadProgress(100);
			return response;
		}

		const errHandle = (error) => {
			clearTimeout(progessTimeOutId.current)
			setPageLoadProgress(100);

			const { response } = error;

			if (!response) {
				return Promise.reject(error);
			}

			if (response.status === 401 &&
				document.location.href.indexOf("sign-in") < 0) {
				console.dir(response);
				setUserInfo();
				navigate("/sign-in");
			}

			return Promise.reject(error);
		}

		instanceAxios.interceptors.request.use(reqHandle, errHandle);
		const cleanUpId = instanceAxios.interceptors.response.use(resHandle, errHandle);

		return () => {
			return instanceAxios.interceptors.response.eject(cleanUpId);
		};
	}, []);

	return children;
}

export async function getData<T>(endpoint: string, query: object = {}, header: object = {}): Promise<[T?, ApiError?]> {
	const source = axios.CancelToken.source();

	const config: AxiosRequestConfig = {
		params: query,
		headers: header,
		cancelToken: source.token,
	};

	try {
		if (endpoint.indexOf("/") > 0) {
			endpoint = `/${endpoint}`;
		}

		const { data } = await instanceAxios.get<ServerResponseResult<T>>(endpoint, config);
		const { result, code } = data;

		if (code !== "200") {
			return [result, data as ApiError] as any;
		}

		return [result] as any;
	} catch (e) {
		const { response } = e as any;
		if (response) {
			return errorFormat(response);
		}

		throw e;
	}
}

export async function postData<T>(endpoint: string, model: object = {}, header: object = {}): Promise<[T?, ApiError?]> {
	const source = axios.CancelToken.source();
	const config: AxiosRequestConfig = {
		headers: header,
		cancelToken: source.token,
	};

	try {
		if (endpoint.indexOf("/") > 0) {
			endpoint = `/${endpoint}`;
		}

		const { data } = await instanceAxios.post<ServerResponseResult<T>>(endpoint, model, config);
		const { result, code } = data;

		if (code !== "200") {
			return [result, data as ApiError] as any;
		}

		return [(result)] as any;
	} catch (e) {
		const { response } = e as any;
		if (response) {
			return errorFormat(response);
		}

		throw e;
	}
}

export async function postFiles<T>(endpoint: string, files: any[], header: object = {}): Promise<[T?, ApiError?]> {
	const formData = new FormData();

	for (const element of files) {
		formData.append("file", element);
	}

	const source = axios.CancelToken.source();
	const config: AxiosRequestConfig = {
		headers: {
			...header,
			'content-type': "multipart/form-data"
		},
		cancelToken: source.token,
	};

	try {
		if (endpoint.indexOf("/") > 0) {
			endpoint = `/${endpoint}`;
		}

		var { data } = await instanceAxios.post<ServerResponseResult<T>>(endpoint, formData, config);
		var { result, code } = data;

		if (code !== "200") {
			return [result, data as ApiError] as any;
		}

		return [result] as any;
	} catch (e) {
		const { response } = e as any;
		if (response) {
			return errorFormat(response);
		}

		throw e;
	}
}

function errorFormat(error) {
	const { data, message, result, status } = error;

	if (message) {
		const toastType = (status !== 500 ? "warning" : "error");
		toast(message, { type: toastType, autoClose: 10000 } as any);
		return [result, error as ApiError] as any;
	}

	toast((data.type ? data.type + " " : "Error ") + data.message, { type: "warning", autoClose: 10000 });
	return [data.result, data as ApiError] as any;
}