import './PlayingCountByPeriodAndGame.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { usePageStore } from '../../stores';
import { GamePlayingCountType } from '../../models';
import { getGamePlayCountByDay } from '../../services';
import { Card, CardBody, CardHeader, FormGroup, Input, Label } from 'reactstrap';
import Highcharts, { SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { parseISO } from 'date-fns';
import { DateRangeModel, DateRangePickerInput } from '../../components';
import { useEffectOnce } from 'usehooks-ts';
import moment from 'moment';

export type PlayingCountByPeriodAndGameProps = {
}

const PlayingCountByPeriodAndGame: React.FC<PlayingCountByPeriodAndGameProps> = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
	const { setPage } = usePageStore();
	const [gamePlayCount, setGamePlayCount] = useState<GamePlayingCountType[]>();

	const [chartConfig, setChartConfig] = useState<Highcharts.Options>({
		credits: {
			enabled: false
		},
		title: {
			text: "Playing by days"
		},
		xAxis: {
			ordinal: false,
			minTickInterval: 24 * 3600 * 1000,
			type: "datetime",
			labels: {
				format: '{value:%Y-%m-%d}'
			}
		},
		yAxis: {
			min: 0,
			title: {
				text: "Play count"
			}
		},
		chart: {
			type: "column"
		},
		plotOptions: {
			column: {
				maxPointWidth: 30
			},
			series: {
				pointInterval: 24 * 3600 * 1000
			}
		},
		tooltip: {
			xDateFormat: '%Y-%m-%d, %A',
		},
		series: []
	});

	useEffectOnce(() => {
		setPage({ title: "Playing by days", pagePath: ["Games"] });
	});

	useEffect(() => {
		if (!searchParams.get("startDate")) {
			searchParams.set("startDate", moment().add(-7, "day").format("yyyy-MM-DD"));
		}

		if (!searchParams.get("endDate")) {
			searchParams.set("endDate", moment().format("yyyy-MM-DD"));
		}

		setSearchParams(searchParams);

		getGamePlayCountByDay(searchParams.get("id"), searchParams.get("startDate"), searchParams.get("endDate")).then(([result, error]) => {
			setGamePlayCount(result?.data);
		});
	}, [searchParams.get("id"), searchParams.get("startDate"), searchParams.get("endDate")])

	useEffect(() => {
		setChartConfig((state) => {
			if (!gamePlayCount?.length) {
				if (state.title)
					state.title.text = "No data";

				state.series = [
					{
						name: "Total Play",
						data: [] as any
					} as SeriesOptionsType
				];

				return { ...state };
			}

			state.series = [];

			let total = 0;
			let games = [...new Set(gamePlayCount.map(item => item.name))];

			games.forEach((gameName) => {
				const seriesData = gamePlayCount
					.filter(o => o.name == gameName)
					.map(o => {
						total += o.totalView;
						return { x: parseISO(o.date).getTime(), y: o.totalView };
					});

				state.series?.push(
					{
						name: gameName,
						data: seriesData
					} as SeriesOptionsType
				);
			})

			if (state.title)
				state.title.text = `${total} times from ${searchParams.get("startDate")} to ${searchParams.get("endDate")}`;

			return { ...state };
		});
	}, [gamePlayCount]);

	return (
		<div className="play-count-by-period-and-game">
			<div className="row mb-2">
				<div className="col-md-6">
					<Card>
						<CardBody>
							<div className="row">
								<div className="col-6">
									<DateRangePickerInput label="Period" name="dayPeriod"
										startDate={searchParams.get("startDate")}
										endDate={searchParams.get("endDate")}
										onChange={(value) => {
											searchParams.set("startDate", value.startDate);
											searchParams.set("endDate", value.endDate);
											setSearchParams(searchParams);
										}} />
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<Card>
						<CardHeader>Summary</CardHeader>
						<CardBody>
							<HighchartsReact
								ref={chartComponentRef}
								highcharts={Highcharts}
								options={chartConfig}
							/>
						</CardBody>
					</Card>
				</div>
			</div>
		</div>
	);
}

PlayingCountByPeriodAndGame.displayName = 'PlayingCountByPeriodAndGame';

export default React.memo(PlayingCountByPeriodAndGame);