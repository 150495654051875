import React, { useMemo, Suspense, lazy } from "react";
import { useRoutes, RouteObject } from "react-router-dom";
import { Role } from './Role';
import {
	ErrorsPage,
	SignIn,
	Dashboard,
	Welcome,
	AddUser,
	AddGame,
	EditGame,
	GameDetail,
	Users,
	GamesStatistics,
	ListGame,
	PlayingCountByPeriod,
	PlayingCountByPeriodAndGame,
	PlayerList,
	ForgotPassword,
    ChangePassword,
    BettingTransactionHistory,
    CachedItemList,
    PurgeCachedItem,
    ListCasino,
    SystemTrace,
    UpdateBalance,
    User
} from "../../pages";
import { ProgessBar } from "../../components";

const PublicLayout = lazy(() => import("../PublicLayout"));
const MasterLayout = lazy(() => import("../MasterLayout"));
const PrivateRoute = lazy(() => import("./PrivateRoute"));

const RoutesList: React.FC = () => {
	const routesList: RouteObject[] = useMemo(() => {
		return [
			{
				element: <PublicLayout />,
				children: [
					{ path: "*", element: <ErrorsPage /> },
					{ path: "/sign-in", element: <SignIn /> },
					{ path: "/forgot-password", element: <ForgotPassword /> },
					{ path: "/welcome", element: <Welcome /> }
				]
			}, {
				element: <MasterLayout />,
				children: [
					{ path: "/", element: <PrivateRoute><Dashboard /></PrivateRoute> },
					{ path: "/home", element: <PrivateRoute><Dashboard /></PrivateRoute> },
					{ path: "/games", element: <PrivateRoute><ListGame /></PrivateRoute> },
					{ path: "/games/add", element: <PrivateRoute><AddGame /></PrivateRoute> },
					{ path: "/games/:id", element: <PrivateRoute><GameDetail /></PrivateRoute> },
					{ path: "/games/edit/:id", element: <PrivateRoute><EditGame /></PrivateRoute> },
					{ path: "/online-players", element: <PrivateRoute><PlayerList /></PrivateRoute> },
					{ path: "/games-statistics", element: <PrivateRoute><GamesStatistics /></PrivateRoute> },
					{ path: "/playing-by-periods", element: <PrivateRoute><PlayingCountByPeriod /></PrivateRoute> },
					{ path: "/playing-by-periods-game", element: <PrivateRoute><PlayingCountByPeriodAndGame /></PrivateRoute> },
					{ path: "/users", element: <PrivateRoute><Users /></PrivateRoute> },
					{ path: "/users/:id", element: <PrivateRoute role={Role.Admin}><User /></PrivateRoute> },
					{ path: "/users/add", element: <PrivateRoute role={Role.Admin}><AddUser /></PrivateRoute> },
					{ path: "/profile/change-password", element: <PrivateRoute><ChangePassword /></PrivateRoute> },
					{ path: "/profile/demo-balance", element: <PrivateRoute><UpdateBalance /></PrivateRoute> },
					{ path: "/profile", element: <PrivateRoute><User /></PrivateRoute> },
					{ path: "/history/betting", element: <PrivateRoute><BettingTransactionHistory /></PrivateRoute> },
					{ path: "/cached-items", element: <PrivateRoute role={Role.Admin}><CachedItemList /></PrivateRoute> },
					{ path: "/purge-cached-item", element: <PrivateRoute role={Role.Admin}><PurgeCachedItem /></PrivateRoute> },
					{ path: "/system-trace", element: <PrivateRoute role={Role.Admin}><SystemTrace /></PrivateRoute> },
					{ path: "/casinos", element: <PrivateRoute role={Role.Admin}><ListCasino /></PrivateRoute> },
					{ path: "/casinos/add", element: <PrivateRoute role={Role.Admin}><ListCasino /></PrivateRoute> }
				]
			}
		]
	}, []);

	const routes = useRoutes(routesList);
	
	return <React.Fragment>
		<Suspense fallback={<ProgessBar value={80} />}>
			{routes}
		</Suspense>
	</React.Fragment>;
}

export default RoutesList;