import React from 'react';
import { usePageStore } from '../../stores';
import { useEffectOnce } from "usehooks-ts";
import './Dashboard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OnlinePlayersWidget, PlayingGamesWidget } from '../../components/Widgets';

export type DashboardProps = {
}

const Dashboard: React.FC<DashboardProps> = () => {
	const { setPage } = usePageStore();

	useEffectOnce(() => {
		setPage({ title: "Dashboard", pagePath: ["Dashboard"] });
	});

	return (
		<div className="dashboard">
			<div className="row">
				<div className="col-xl-3 col-md-6 mb-4">
					<OnlinePlayersWidget />
				</div>
				<div className="col-xl-3 col-md-6">
					<div className="card bg-warning text-white mb-4">
						<div className="card-body">Warning Card</div>
						<div className="card-footer d-flex align-items-center justify-content-between">
							<a className="small text-white stretched-link" href="#">View Details</a>
							<div className="small text-white"><FontAwesomeIcon icon={"angle-right"} /></div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-md-6">
					<div className="card bg-danger text-white mb-4">
						<div className="card-body">Danger Card</div>
						<div className="card-footer d-flex align-items-center justify-content-between">
							<a className="small text-white" href="#">View Details</a>
							<div className="small text-white"><FontAwesomeIcon icon={"angle-right"} /></div>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="mb-4">
						<PlayingGamesWidget className="" />
					</div>
				</div>
			</div>
		</div>
	);
}

Dashboard.displayName = 'Dashboard';

export default React.memo(Dashboard);