import './EditGame.scss';
import React, { useCallback, useRef } from 'react';
import { GameInfo } from '../../models';
import { AddEditGameForm } from '../../components';
import { toast } from 'react-toastify';
import { updateGame, getGameDetail } from '../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce, useToggle } from 'usehooks-ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePageStore } from '../../stores';

const EditGame: React.FC = () => {
	const navigate = useNavigate();
	const params = useParams();
	const game = useRef<GameInfo>();
	const [isLoading, toggleLoading] = useToggle();

	const { setPage } = usePageStore();

	useEffectOnce(() => {
		setPage({ title: "Game Manage", pagePath: ["Edit Game"] });
	});

	useEffectOnce(() => {
		if (!params.id) {
			navigate("/games");
			return () => {

			};
		}

		toggleLoading();
		getGameDetail(params.id).then(([result, error]) => {
			toggleLoading();

			if (result) {
				game.current = result;
				setPage({ title: "Game Manage", pagePath: ["Edit Game",  result.name] });
			}
		});

		return () => {

		}
	});

	const submitHandle = useCallback((model: GameInfo) => {
		updateGame(model).then(([result, error]) => {
			if (error?.code !== 200) {
				toast(`Error Update Game ${model.name}: ${error?.message}.`, { type: "error" });
				return;
			}

			//if ((result as any).errorType) {
			//	toast((result as any).errorType, { type: "warning"});
			//}

			toast(`Game ${model.name} is updated.`, { type: "success" });
			navigate("/games");
		});
	}, []);

	return (
		<div className="edit-game">
			{
				isLoading && <p className="text-center">
					<FontAwesomeIcon icon="spinner" className="fa-spin"/>
				</p>
			}
			{
				game.current && <AddEditGameForm selectedGame={game.current} onSubmit={submitHandle} />
			}
		</div>
	);
}

EditGame.displayName = 'EditGame';

export default React.memo(EditGame);