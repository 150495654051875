import "./Welcome.scss";
import React, { useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useUserStore } from "../../stores";
import { useToggle } from "usehooks-ts";
import { ChangePasswordType } from "../../models";
import { changePassword } from "../../services";
import { ChangePasswordForm } from "../../components";

export type WelcomeProps = {
}

const Welcome: React.FC<WelcomeProps> = () => {
	const navigate = useNavigate();
	const { user, getUserInfo } = useUserStore();
	const [isUpdating, toggleUpdating] = useToggle(false);

	const passwordSubmitHandle = useCallback((model: ChangePasswordType) => {
		toggleUpdating();
		changePassword(model).then(([isChanged, error]) => {
			toggleUpdating();

			if (isChanged) {
				// refresh user data in session
				toggleUpdating();
				getUserInfo().then(() => {
					toggleUpdating();
					navigate("/");
				});
			}
		});
	}, []);

	if (!user?.id) {
		return <Navigate to="/sign-in" />;
	}

	if (!user.isForcePasswordChange) {
		return <Navigate to="/" />;
	}

	return (
		<div className="welcome">
			<h2 className="my-3 text-center text-white">Welcome, {user.userName}</h2>
			<div className="row mt-3">
				<div className="col-md-12 text-center text-white">
					To continue, please change your password.
				</div>
			</div>
			<div className="row mt-3 justify-content-center">
				<div className="col-md-6">
					<ChangePasswordForm onPasswordSubmit={passwordSubmitHandle} isUpdating={isUpdating} />
				</div>
			</div>
		</div>
	);
}

Welcome.displayName = "Welcome";

export default React.memo(Welcome);