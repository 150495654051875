import { ListResult } from "../models";
import { getData, postData } from "./axios/ConfigAxios";

export async function getTempleteCacheKeys() {
	return await getData<string[]>("/api/cache/key-template");
}

export async function getCacheKeys(filter: any) {
	return await getData<ListResult<string>>("/api/cache/keys", filter);
}

export async function getCacheKeyDetail(key: string) {
	return await getData<string>(`/api/cache/keys/${key}`);
}

export async function deleteCacheByKey(key: string) {
	return await postData<boolean>("/api/cache/remove", {key});
}