import './UpdateBalance.scss';
import React, { useState } from 'react';
import { usePageStore, useUserStore } from '../../stores';
import { useEffectOnce, useToggle } from 'usehooks-ts';
import { Card, CardBody, CardFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { depositBalance, loadBalance } from '../../services';

const UpdateBalance: React.FC = () => {
	const { setPage } = usePageStore();
	const [depositAmount, setDepositAmount] = useState(0);
	const [isUpdating, toggleUpdating] = useToggle();
	const { currentBalance, setCurrentBalance } = useUserStore();

	useEffectOnce(() => {
		setPage({ title: "Update demo balance", pagePath: ["Profile", "Demo Balance"] });
		onLoad()
	});

	const onLoad = async function () {
		toggleUpdating();

		const [result] = await loadBalance();
		toggleUpdating();

		if (result) {
			setCurrentBalance(result);
		}
	}

	const onUpdate = async function (e) {
		e.preventDefault();
		toggleUpdating();

		const [result] = await depositBalance(currentBalance + depositAmount);
		toggleUpdating();

		if (result) {
			setDepositAmount(0);
			setCurrentBalance(result);
		}
	}

	return (
		<div className="update-balance">
			<div className="row mt-3 justify-content-center">
				<div className="col-md-4">
					<Form onSubmit={onUpdate}>
						<Card>
							<CardBody>
								<div className="row">
									<div className="col-12 position-relative">
										<Label for="password">
											Current:  {isUpdating ? "-" : currentBalance}<FontAwesomeIcon icon={"dollar"} className={"fa-fw field-icon"} />
										</Label>
									</div>
								</div>
								<div className="row">
									<div className="col-12 position-relative">
										<FormGroup floating>
											<Input
												name="depositAmount"
												type="number"
												min="0"
												max="1000000"
												required
												minLength={8}
												value={depositAmount}
												onChange={e => setDepositAmount(+e.target.value)}
											/>
											<Label for="newPassword">
												Deposit Amount
											</Label>
										</FormGroup>
										<FontAwesomeIcon icon={"up"} className={"fa-fw field-icon toggle-password"} />
									</div>
								</div>
							</CardBody>
							<CardFooter>
								<div className="row">
									<div className="col-12 text-center">
										<button type="submit" className="btn btn-primary" disabled={isUpdating}><FontAwesomeIcon icon="plus" /> Deposit</button>
									</div>
								</div>
							</CardFooter>
						</Card>
					</Form>
				</div>
			</div>
		</div>
	);
}

UpdateBalance.displayName = 'UpdateBalance';

export default React.memo(UpdateBalance);