import React, { useEffect, useState } from 'react';
import './AddCasinoModal.scss';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CasinoModel } from '../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

export type AddCasinoModalProps = {
	isSaving: boolean;
	onSubmit: (data: any) => void
	onClose: () => void
}

const AddCasinoModal: React.FC<AddCasinoModalProps> = ({ isSaving, onSubmit, onClose }) => {
	const [casino, setCasino] = useState<CasinoModel>({ } as CasinoModel);

	const submitHandle = () => {
		try {
			if (!casino) {
				toast("Invalid config format", { type: "warning" });
				return;
			}

			//if (!casino.config.startsWith("{") || !casino.config.endsWith("}")) {
			//	toast("Invalid config format", { type: "error" });
			//	return;
			//}

			//const config = JSON.parse(casino.config);

			//if (!config) {
			//	toast("Invalid config format", { type: "warning" });
			//	return;
			//}

		} catch (e) {
			toast("Invalid config format", { type: "warning" });
			return;
		}

		onSubmit(casino);
	}

	return (
		<div className="add-casino-modal">
			<Modal isOpen={true} size="lg">
				<ModalHeader toggle={onClose}>Add Casino</ModalHeader>
				{
						<React.Fragment>
							<ModalBody>
								<div className="row">
									<div className="col-12">
										<FormGroup floating>
										<Input
											name="name"
											placeholder="Name"
											type="text"
											required
											value={casino.name || ''}
												maxLength={100}
											onChange={e => setCasino({ ...casino, name: e.target.value })}
											onBlur={
												e => {
													if (!casino.id) {
														setCasino({ ...casino, id: e.target.value.replaceAll(" ", "").replaceAll("'s", "") });
													}
												}
											}
											/>
											<Label for="name">
												Name
											</Label>
										</FormGroup>
									</div>
							</div>
							<div className="row">
								<div className="col-12">
									<FormGroup floating>
										<Input
											name="id"
											placeholder="Id"
											type="text"
											required
											value={casino.id || ''}
											maxLength={100}
											onChange={e => setCasino({ ...casino, id: e.target.value })}
										/>
										<Label for="id">
											Id
										</Label>
									</FormGroup>
								</div>
							</div>
								<div className="row">
									<div className="col-12">
										<FormGroup floating>
										<Input
											name="status"
											placeholder="Status"
											type="text"
											required
											value={casino.status || ''}
												maxLength={100}
												onChange={e => setCasino({ ...casino, status: e.target.value })}
											/>
											<Label for="status">
												Status
											</Label>
										</FormGroup>
									</div>
							</div>
							<div className="row">
								<div className="col-12">
									<FormGroup floating>
										<Input
											name="apiKey"
											placeholder="ApiKey"
											type="text"
											required
											value={casino.apiKey || ''}
											maxLength={100}
											onChange={e => setCasino({ ...casino, apiKey: e.target.value })}
										/>
										<Label for="apiKey">
											Api Key
										</Label>
									</FormGroup>
								</div>
							</div>
								{/*<div className="row">*/}
								{/*	<div className="col-12">*/}
								{/*		<FormGroup floating>*/}
								{/*			<Input*/}
								{/*				name="config"*/}
								{/*				placeholder="Config"*/}
								{/*				type="textarea"*/}
								{/*				required*/}
								{/*				value={casino.config}*/}
								{/*				className="textarea-resizeable"*/}
								{/*				onChange={e => setCasino({ ...casino, config: e.target.value })}*/}
								{/*			/>*/}
								{/*			<Label for="config">*/}
								{/*				Config (json)*/}
								{/*			</Label>*/}
								{/*		</FormGroup>*/}
								{/*	</div>*/}
								{/*</div>*/}
							</ModalBody>
							<ModalFooter>
								<Button color="warning" type="button" disabled={!casino.name || isSaving} onClick={submitHandle}>
									<span>Submit</span> {isSaving && <FontAwesomeIcon icon="spinner" className="fa-spin" />}
								</Button>{' '}
								<Button color="secondary" onClick={onClose}>
									Close
								</Button>
							</ModalFooter>
						</React.Fragment>
				}
			</Modal>
		</div >
	);
}

AddCasinoModal.displayName = 'AddCasinoModal';

export default React.memo(AddCasinoModal);