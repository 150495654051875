import { Card, CardBody, CardFooter, CardHeader, Table } from 'reactstrap';
import './PlayerList.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { SearchBar } from '../../components/SearchBar';
import { useEffectOnce } from 'usehooks-ts';
import { usePageStore } from '../../stores';
import { Link, useSearchParams } from 'react-router-dom';
import { getOnlinePlayer } from '../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseISO } from 'date-fns';
import { ListResult, PlayerOnlineType } from '../../models';
import { useQuery } from 'react-query';

export type PlayerListProps = {
}

const PlayerList: React.FC<PlayerListProps> = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { setPage } = usePageStore();
	const [players, setPlayers] = useState<ListResult<PlayerOnlineType>>();

	useEffectOnce(() => {
		setPage({ title: "Online Players", pagePath: ["Online Players"] });
	});

	const { isLoading, data } = useQuery("getOnlinePlayer", () => {
			getOnlinePlayer().then(([result]) => {
				setPlayers(result);
			});
		},
		{
			refetchOnWindowFocus: true,
			refetchInterval: 30000
		});

	useEffect(() => {
		if (!players?.data) {
			return;
		}

		const items = [...players.data];

		items.sort((a, b) => {
			const order = searchParams.get("order");
			const sortCol = searchParams.get("sort") || "joinedDate";

			if (order == "desc") {
				return a[sortCol] < b[sortCol] ? 1 : -1;
			}

			return a[sortCol] > b[sortCol] ? 1 : -1;
		});

		setPlayers({ total: players.total, data: items });
	}, [searchParams, data]);

	const setQuery = useCallback((key: string, value: string) => {
		searchParams.set(key, value);
		setSearchParams(searchParams);
	}, [searchParams]);

	const sortHandle = useCallback((e: any) => {
		let target = e.target;

		if (target.tagName != "th") {
			target = target.closest("th");
		}

		const col = target.dataset.colName;

		if (searchParams.get("sort") != col) {
			setQuery("sort", col);
		}
		else {
			setQuery("order", searchParams.get("order") == "asc" ? "desc" : "asc");
		}
	}, [searchParams]);

	const getOrderDirection = useCallback((col: string): string => {
		return col != searchParams.get("sort") ? "" : (searchParams.get("order") || "");
	}, [searchParams]);

	return (
		<div className="player-list">
			<Card className="card mb-4">
				<CardHeader>
					<div className="row">
						<div className="col-md-3">
							<SearchBar
								onKeywordChange={key => setQuery("keyword", key)}
								defaultKeyword={searchParams.get("keyword") ?? ""}
								title="Find" />
						</div>
					</div>
				</CardHeader>
				<CardBody className="table-responsive">
					<Table className="table-striped" hover>
						<thead>
							<tr className="text-capitalize">
								<th>Session</th>
								<th onClick={sortHandle} data-col-name="gameName" className={getOrderDirection("gameName")}><span className="datatable-sorter">Game</span></th>
								<th onClick={sortHandle} data-col-name="casinoId" className={getOrderDirection("casinoId")}><span className="datatable-sorter">Casino</span></th>
								<th onClick={sortHandle} data-col-name="playerId" className={getOrderDirection("playerId")}><span className="datatable-sorter">Player</span></th>
								<th onClick={sortHandle} data-col-name="playerCountry" className={getOrderDirection("playerCountry")}><span className="datatable-sorter">Country</span></th>
								<th onClick={sortHandle} data-col-name="joinedDate" className={getOrderDirection("joinedDate")} ><span className="datatable-sorter">Joined date</span></th>
								<th style={{ width: 150 }}>Browser Agent</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{
								players?.data && players.data.length > 0
								&& players.data.map((item: PlayerOnlineType, index) => {
									return (
										<tr key={index}>
											<th>{item.sessionId}</th>
											<td><Link to={"/games/" + item.gameId}>{item.gameName}</Link></td>
											<td>{item.casinoId}</td>
											<td>{item.playerId}</td>
											<td>{item.playerCountry} | {item.playerIp}</td>
											<td>{item.joinedDate && parseISO(item.joinedDate).toLocaleString()}</td>
											<td>{item.browserAgent}</td>
											<td>
												<FontAwesomeIcon icon="file-lines" title="View action logs" />
											</td>
										</tr>
									);
								})}
						</tbody>
					</Table>
				</CardBody>
				<CardFooter>
					{ !players ? null : <span>{players.total} players</span>}
				</CardFooter>
			</Card>
		</div>
	);
}

PlayerList.displayName = 'PlayerList';

export default React.memo(PlayerList);