import { get } from "http";
import { ApiError, SignInModelType, UserInfoType, ListResult, ListUserQueryType, SignInCasinoModelType } from "../models";
import { getData, postData } from "./axios/ConfigAxios";

export async function getUserSession(): Promise<[UserInfoType?, ApiError?]> {
	return await getData<UserInfoType>("/api/session");
}

export async function signIn(model: SignInModelType): Promise<[UserInfoType?, ApiError?]> {
	return await postData<UserInfoType>("/api/sign-in", model);
}

export async function signOut(): Promise<[boolean?, ApiError?]> {
	return await postData<boolean>("/api/session/sign-out", {});
}

export async function listUser(model: ListUserQueryType = {} as ListUserQueryType): Promise<[ListResult<UserInfoType>?, ApiError?]> {
	return await getData<ListResult<UserInfoType>>("/api/user", model);
}

export async function getUser(id: number): Promise<[UserInfoType?, ApiError?]> {
	return await getData<UserInfoType>(`/api/user/${id}`);
}

export async function addUser(model: UserInfoType): Promise<[UserInfoType?, ApiError?]> {
	console.log(model);
	return await postData<UserInfoType>("/api/user/add", model);
}

export async function updateUser(model: UserInfoType): Promise<[boolean?, ApiError?]> {
	return await postData<boolean>("/api/user/edit", model);
}

export async function updateUserStatus(model: UserInfoType): Promise<[boolean?, ApiError?]> {
	return await postData<boolean>("/api/user/update-status", model);
}

export async function addCasinoUser(model: SignInCasinoModelType): Promise<[UserInfoType?, ApiError?]> {
	return await postData<UserInfoType>("/api/user/add-for-portal", model);
}