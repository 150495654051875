import "./PlayingCountByPeriod.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Highcharts, { SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getLastPlayedGameByPeriod } from "../../services";
import { GamePlayingCountType } from "../../models";
import { usePageStore } from "../../stores";
import { useEffectOnce } from "usehooks-ts";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { Link, useSearchParams } from "react-router-dom";
import { parseISO } from "date-fns";
import { DateRangePickerInput } from "../../components";
import moment from "moment";

export type PlayingCountByPeriodProps = {
}

const PlayingCountByPeriod: React.FC<PlayingCountByPeriodProps> = () => {
	const { setPage } = usePageStore();
	const [searchParams, setSearchParams] = useSearchParams();
	const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
	const [gamePlayCount, setGamePlayCount] = useState<GamePlayingCountType[]>();
	const [chartConfig, setChartConfig] = useState<Highcharts.Options>({
		credits: {
			enabled: false
		},
		title: {
			text: "Playing by days"
		},
		yAxis: {
			min: 0,
			title: {
				text: "Play count"
			}
		},
		chart: {
			type: "column"
		},
		plotOptions: {
			column: {
				maxPointWidth: 30
			}
		},
		series: []
	});
	
	useEffectOnce(() => {
		setPage({ title: "Playing by days", pagePath: ["Periods"] });

		if(!searchParams.get("startDate")) {
			searchParams.set("startDate", moment().add(-7, "day").format("yyyy-MM-DD"));
		}

		if(!searchParams.get("endDate")) {
			searchParams.set("endDate", moment().format("yyyy-MM-DD"));			
		}

		setSearchParams(searchParams);
	});

	useEffect(() => {
		getLastPlayedGameByPeriod(searchParams.get("startDate"), searchParams.get("endDate")).then(([result]) => {
			setGamePlayCount(result?.data);
		});	
	}, [searchParams.get("startDate"), searchParams.get("endDate")]);

	useEffect(() => {
		setGamePlayCount((state) => {
			if (!state) {
				return state;
			}

			state.sort((a, b) => {
				const order = searchParams.get("order");
				const sortCol = searchParams.get("sort") ?? "lastPlayed";

				if (order == "desc") {
					return a[sortCol] < b[sortCol] ? 1 : -1;
				}

				return a[sortCol] > b[sortCol] ? 1 : -1;
			});

			return [...state];
		});
	}, [searchParams.get("order"), searchParams.get("sort")]);

	useEffect(() => {
		setChartConfig((state) => {
			if (!gamePlayCount?.length) {
				if (state.title)
					state.title.text = "No data";

				state.series = [
					{
						name: "Total Play",
						data: [] as any
					} as SeriesOptionsType
				];

				return { ...state };
			}

			let total = 0;
			const seriesData = gamePlayCount.map(o => {
				total += o.totalView;
				return o.totalView;
			});

			state.xAxis = {
				categories: gamePlayCount.map(o => { return o.name; }),
			} as any;

			state.series = [
				{
					name: "Total Play",
					data: seriesData
				} as SeriesOptionsType
			];

			if (state.title)
				state.title.text = `${total} times from ${searchParams.get("startDate")} to ${searchParams.get("endDate")}`;

			return { ...state };
		});
	}, [gamePlayCount]);

	const setQuery = useCallback((key: string, value: string) => {
		searchParams.set(key, value);
		setSearchParams(searchParams);
	}, [searchParams]);

	const sortHandle = useCallback((e: any) => {
		let target = e.target;

		if (target.tagName != "th") {
			target = target.closest("th");
		}

		const col = target.dataset.colName;

		if (searchParams.get("sort") != col) {
			setQuery("sort", col);
		}
		else {
			setQuery("order", searchParams.get("order") != "desc" ? "desc" : "asc");
		}
	}, [searchParams, setQuery]);

	const getOrderDirection = useCallback((col: string): string => {
		return col != searchParams.get("sort") ? "lastPlayed" : (searchParams.get("order") || "desc");
	}, [searchParams]);
	
	return (
		<div className="playing-count-by-period">
			<div className="row mb-2">
				<div className="col-md-6">
					<Card>
						<CardBody>
							<div className="row">
								<div className="col-md-6">
									<DateRangePickerInput label="Period" name="dayPeriod"
										startDate={searchParams.get("startDate")}
										endDate={searchParams.get("endDate")}
										onChange={(value) => {
											searchParams.set("startDate", value.startDate)
											searchParams.set("endDate", value.endDate)
											setSearchParams(searchParams);
										}} />
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-6">
					<Card>
						<CardHeader>Summary</CardHeader>
						<CardBody>
							<HighchartsReact
								ref={chartComponentRef}
								highcharts={Highcharts}
								options={chartConfig}
							/>
						</CardBody>
					</Card>
				</div>
				<div className="col-lg-6">
					<Card>
						<CardHeader>Details</CardHeader>
						<CardBody className="table-responsive">
							<Table className="table-striped" hover>
								<thead>
									<tr className="text-capitalize">
										<th onClick={sortHandle} data-col-name="name" className={getOrderDirection("name")}><span className="datatable-sorter">Game</span></th>
										<th onClick={sortHandle} data-col-name="totalView" className={getOrderDirection("totalView")}><span className="datatable-sorter">Total</span></th>
										<th onClick={sortHandle} data-col-name="lastPlayed" className={getOrderDirection("lastPlayed")} ><span className="datatable-sorter">Last played</span></th>
									</tr>
								</thead>
								<tbody>
									{
										!gamePlayCount?.length || gamePlayCount.length === 0
											? null :
											gamePlayCount.map((item: GamePlayingCountType) => {
												return (
													<tr key={item.name}>
														<th scope="row"><Link title="View detail" to={`/playing-by-periods-game?id=${item.id}&startDate=${searchParams.get("startDate")}&endDate=${searchParams.get("endDate")}`}>{item.name}</Link></th>
														<td>{item.totalView}</td>
														<td>{item.lastPlayed && parseISO(item.lastPlayed).toLocaleString()}</td>
													</tr>
												);
											})}
								</tbody>
							</Table>
						</CardBody>
					</Card>
				</div>
			</div>
		</div>
	);
}

PlayingCountByPeriod.displayName = "PlayingCountByPeriod";

export default React.memo(PlayingCountByPeriod);