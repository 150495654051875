import './ForgotPassword.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useEffectOnce, useToggle } from 'usehooks-ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { otpRequest, resetPassword } from '../../services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { usePageStore, useUserStore } from '../../stores';

const ForgotPassword: React.FC = () => {
	const [email, setEmail] = useState<string>("");
	const [otp, setOtp] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [isLoading, toggleLoading] = useToggle(false);
	const [isShowPass, toggleShowPass] = useToggle(false);
	const [isOtpRequested, toggleOtpRequested] = useToggle();
	const [isResendAbleIn, setIsResendAbleIn] = useState<number>(0);
	const otpCountDown = useRef<any>();
	const navigate = useNavigate();
	const { setPage } = usePageStore();
	const { signOut, user } = useUserStore();

	useEffectOnce(() => {
		if(user) {
			navigate("/");
			return;
		}

		setPage({ title: "Reset Password", pagePath: [""] });
	});

	useEffect(() => {
		if (!isOtpRequested) {
			return;
		}
		if (otpCountDown.current) {
			clearInterval(otpCountDown.current);
		}

		setIsResendAbleIn(60);

		otpCountDown.current = setInterval(() => {
			setIsResendAbleIn((state) => {
				if (state <= 0) {
					clearInterval(otpCountDown.current);
				}

				return --state;
			});
		}, 1000);

		return () => {
			clearInterval(otpCountDown.current);
		}
	}, [isOtpRequested])

	const handleEmailSubmit = useCallback(async (e) => {
		e.preventDefault();

		if (isResendAbleIn > 0 || isLoading) {
			return;
		}

		setOtp("");
		toggleLoading();
		let [result] = await otpRequest(email);
		toggleLoading();

		if (result) {
			toast("OTP is sent to your mail.", { type: "success" });
			toggleOtpRequested();
		}
	}, [isResendAbleIn, isLoading, email]);

	const handleOtpSubmit = useCallback(async (e) => {
		e.preventDefault();
		setIsResendAbleIn(0);
		clearInterval(otpCountDown.current);

		toggleLoading();
		let [result] = await resetPassword({ email, otp, password });
		toggleLoading();

		if (result) {
			toast("Password is updated.", { autoClose: false, type: "success" });
			await signOut();
			navigate("/sign-in");
		}
	}, [otpCountDown.current, email, otp, password]);

	return (
		<div className="forgot-password">
			<div className="row justify-content-center">
				<h2 className="my-3 text-center text-white">Forgot password</h2>
				<div className="col-md-8 col-lg-5">
					{
						isOtpRequested ?
							null :
							<form onSubmit={handleEmailSubmit} className="forgot-pass-form">
								<div className="form-group mt-3">
									<label className="form-control-placeholder" htmlFor="email">Email</label>
									<input type="email"
										placeholder="Email"
										className="form-control"
										name="email"
										required
										defaultValue={email}
										onChange={(e) => setEmail(e.target.value)} />
								</div>
								<div className="text-center">
									<button type="submit" className="btn btn-primary rounded submit mt-2 px-3" disabled={isLoading}>Submit</button>
								</div>
							</form>
					}
					{
						!isOtpRequested ?
							null :
							<form onSubmit={handleOtpSubmit} className="forgot-pass-form">
								<div className="row form-group mt-3">
									<div className="col-md-4">
										<label className="form-control-placeholder" htmlFor="email">OTP</label>
										<input type="text"
											placeholder="Enter OTP"
											maxLength={6}
											className="form-control"
											name="otp"
											required
											defaultValue={otp}
											onChange={(e) => setOtp(e.target.value.trim())} />
									</div>
									{
										isResendAbleIn > 0 ?
											<div className="form-text">Resend available in {isResendAbleIn} seconds.</div> :
											<div className="form-text resend-otp" onClick={(e) => handleEmailSubmit(e)}>Resend OTP</div>
									}
								</div>
								<div className="form-group">
									<label className="form-control-placeholder" htmlFor="password">New Password</label>
									<div className="position-relative">
										<input id="password-field" type={isShowPass ? "text" : "password"}
											className="form-control"
											required
											minLength={6}
											placeholder="New Password"
											defaultValue={password}
											onChange={(e) => {
												setPassword(e.target.value);
											}} />
										<FontAwesomeIcon icon={(isShowPass ? "eye-slash" : "eye")}
											className={"fa-fw field-icon toggle-password"}
											onClick={toggleShowPass} />
									</div>
								</div>
								<div className="text-center">
									<button type="reset" className="btn btn-secondary rounded mt-2 px-3">Back</button>
									<button type="submit" className="btn btn-primary rounded submit mt-2 ms-1 px-3" disabled={isLoading}>Change</button>
								</div>
							</form>
					}
				</div>
			</div>
		</div>
	);
}

ForgotPassword.displayName = 'ForgotPassword';

export default React.memo(ForgotPassword);