import "rc-pagination/assets/index.css";
import "./PagingPanel.scss";
import React from "react";
import Pagination from "rc-pagination";

export type PagingPanelProps = {
	total: number,
	page?: string | null;
	pageSize?: string | null;
	onChange: (page: number) => void;
	onPageSizeChange?: (pageSize: number) => void;
}

const PagingPanel: React.FC<PagingPanelProps> = ({ total, page, onChange, pageSize, onPageSizeChange }) => {
	return (
		<div className="paging-panel datatable-bottom">
			<Pagination 
				total={total}
				current={+(page || 1)}
				className="d-flex justify-content-center"
				pageSize={+(pageSize || 10)}
				showTotal={(totalItem, [from, to]) => <div className="datatable-info">Showing {from} to {to} of {totalItem} entries</div>}
				onChange={onChange}
				showSizeChanger={typeof onPageSizeChange == "function"}
				onShowSizeChange={(current) => {
					if (typeof onPageSizeChange == "function") {
						onPageSizeChange(current);
					}
				}} />
		</div>
	);
}

PagingPanel.displayName = "PagingPanel";

export default React.memo(PagingPanel);