import './BettingTransactionHistoryResult.scss';
import React, { useCallback } from 'react';
import { PagingPanel } from '../PagingPanel';
import { Card, CardBody, CardFooter, CardHeader, Table } from 'reactstrap';
import { ListResult } from '../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  BettingTransactionHistoryResultModel } from './BettingTransactionHistoryFilterModel';
import moment from 'moment';
import { parseISO } from 'date-fns';

export type BettingTransactionHistoryResultProps = {
	result?: ListResult<BettingTransactionHistoryResultModel>;
	defaultPage?: string | null;
	defaultPageSize?: string | null;
	pageSizeChangeHandle: (pageSize: number) => void;
	searchByCol: (data: any) => void;
	pageChangeHandle: (page: number) => void;
}

const BettingTransactionHistoryResult: React.FC<BettingTransactionHistoryResultProps> = ({ result, defaultPage, defaultPageSize, searchByCol, pageSizeChangeHandle, pageChangeHandle }) => {
	const sortHandle = useCallback((e) => {
	}, []);

	const getOrderDirection = useCallback((col: string) => {
		return "";
	}, []);

	return (
		<div className="betting-transaction-history-result">
			<Card className="card mb-4">
				<CardHeader>
					{
						result &&
						<PagingPanel total={result.total}
							pageSize={defaultPageSize}
							page={defaultPage}
							onChange={pageChangeHandle}
							onPageSizeChange={pageSizeChangeHandle}
						/>
					}
				</CardHeader>
				<CardBody className="table-responsive">
					<Table className="table-striped" hover>
						<thead>
							<tr className="text-capitalize">
								<th>Round</th>
								<th>Transaction</th>
								<th onClick={sortHandle} data-col-name="type" className={getOrderDirection("type")}><span className="datatable-sorter">Type</span></th>
								<th onClick={sortHandle} data-col-name="creationTime" className={getOrderDirection("creationTime")}><span className="datatable-sorter">Date</span></th>
								<th onClick={sortHandle} data-col-name="sessionId" className={getOrderDirection("sessionId")}><span className="datatable-sorter">Session</span></th>
								<th>Currency</th>
								<th>Bet</th>
								<th>Payout</th>
								<th>Payout (EUR)</th>
								<th>Change</th>
								<th>Turnover</th>
								<th onClick={sortHandle} data-col-name="gameId" className={getOrderDirection("casinoId")} ><span className="datatable-sorter">Casino</span></th>
								<th onClick={sortHandle} data-col-name="playerId" className={getOrderDirection("playerId")} ><span className="datatable-sorter">Player Id</span></th>
								<th>Data</th>
							</tr>
						</thead>
						<tbody>
							{
								!result?.data?.length
									? null :
									result.data.map((item: BettingTransactionHistoryResultModel) => {
										let gameData = JSON.parse(item.data);
										return (
											<tr key={item.id}>
												<td>{item.roundId}</td>
												<td>{item.transactionId}</td>
												<td><span className="btn-link" onClick={(e) => searchByCol({ type: item.type })}>{item.type}</span></td>
												<td><span className="btn-link" onClick={() => {
													const dateQuery = moment.utc(item.creationTime).format("yyyy-MM-DD")
													searchByCol({ fromDate: dateQuery, toDate: dateQuery })
													}}>{parseISO(item.creationTime).toLocaleString()}</span></td>
												<td><span className="btn-link" onClick={() => searchByCol({ sessionId: item.sessionId })}>{item.sessionId}</span></td>
												<td className="text-right">{item.currencyCode}</td>
												<td className="text-right">{gameData.bet}</td>
												<td className="text-right">{gameData.win}</td>
												<td className="text-right">{gameData.winInEur}</td>
												<td className="text-right">{gameData.change}</td>
												<td className="text-right">{gameData.win + gameData.bet}</td>
												<td><span className="btn-link" onClick={() => searchByCol({ casinoId: item.casinoId })}>{item.casinoId}</span></td>
												<td><span className="btn-link" onClick={() => searchByCol({ playerId: item.playerId })}>{item.playerId}</span></td>
												<td className="col data">
													<div className="math-data-block">
														<span className="icon"><FontAwesomeIcon icon="eye" /> See data</span>
														<span className="math-data">{item.data}</span>
													</div>
												</td>
											</tr>
										);
									})}
						</tbody>
					</Table>
				</CardBody>
				<CardFooter>
					{
						result &&
						<PagingPanel total={result.total}
							pageSize={defaultPageSize}
							page={defaultPage}
							onChange={pageChangeHandle}
							onPageSizeChange={pageSizeChangeHandle}
						/>
					}
				</CardFooter>
			</Card>
		</div>
	);
}

BettingTransactionHistoryResult.displayName = 'BettingTransactionHistoryResult';

export default React.memo(BettingTransactionHistoryResult);