import './SystemTraceResult.scss';
import React from 'react';
import { PagingPanel } from '../PagingPanel';
import { Card, CardBody, CardFooter, CardHeader, Table } from 'reactstrap';
import { ListResult } from '../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SystemTraceFilterModel, SystemTraceResultModel } from './SystemTraceFilterModel';
import moment from 'moment';

export type SystemTraceResultProps = {
	result?: ListResult<SystemTraceResultModel>;
	defaultPage?: string | null;
	defaultPageSize?: string | null;
	defaultFilter?: SystemTraceFilterModel;
	pageSizeChangeHandle: (pageSize: number) => void;
	searchByCol: (data: any) => void;
	pageChangeHandle: (page: number) => void;
	viewDetailHandle: (item) => void;
}

const SystemTraceResult: React.FC<SystemTraceResultProps> = ({ result, defaultPage, defaultPageSize, searchByCol, pageSizeChangeHandle, pageChangeHandle, viewDetailHandle }) => {
	return (
		<div className="system-trace-result">
			<Card className="card mb-4">
				<CardHeader>
					{
						result &&
						<PagingPanel total={result.total}
							pageSize={defaultPageSize}
							page={defaultPage}
							onChange={pageChangeHandle}
							onPageSizeChange={pageSizeChangeHandle}
						/>
					}
				</CardHeader>
				<CardBody className="table-responsive">
					<Table className="table-striped" hover>
						<thead>
							<tr className="text-capitalize col">
								<th>Id</th>
								<th className="module"><span >Module</span></th>
								<th className="type"><span >Type</span></th>
								<th><span >Source</span></th>
								<th className="date"><span >Date</span></th>
								<th>Message</th>
								<th><span >New</span></th>
								<th className="data">Detail</th>
							</tr>
						</thead>
						<tbody>
							{
								!result?.data?.length
									? null :
									result.data.map((item: SystemTraceResultModel) => {
										const dateData = moment(item.createdTime);

										return (
											<tr key={item.id}>
												<td>{item.id}</td>
												<td><span className="btn-link" onClick={(e) => searchByCol({ module: item.module })}>{item.module}</span></td>
												<td><span className="btn-link" onClick={(e) => searchByCol({ type: item.type })}>{item.type}</span></td>
												<td><span className="btn-link" onClick={() => searchByCol({ source: item.source })}>{item.source}</span></td>
												<td><span className="btn-link" onClick={() => {
													let dateStr = dateData.format("yyyy-MM-DD");
													searchByCol({ fromDate: dateStr, toDate: dateStr })
												}}>{dateData.format("yyyy-MM-DD hh:mm:ss")}</span></td>
												<td>{item.message}</td>
												<td>{!item.isSent ? <FontAwesomeIcon icon="check" /> : null}</td>
												<td>
													<span className="btn-link" onClick={(e) => {
														e.preventDefault();
														viewDetailHandle(item);
													}}><FontAwesomeIcon icon="eye" /> Detail</span>
												</td>
											</tr>
										);
									})}
						</tbody>
					</Table>
				</CardBody>
				<CardFooter>
					{
						result &&
						<PagingPanel total={result.total}
							pageSize={defaultPageSize}
							page={defaultPage}
							onChange={pageChangeHandle}
							onPageSizeChange={pageSizeChangeHandle}
						/>
					}
				</CardFooter>
			</Card >
		</div >
	);
}

SystemTraceResult.displayName = 'SystemTraceResult';

export default React.memo(SystemTraceResult);