import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parseISO } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { useEffectOnce } from "usehooks-ts";
import { SearchBar } from "../../components/SearchBar";
import { Role } from "../../layouts/routing/Role";
import { ListResult, ListUserQueryType, UserInfoType } from "../../models";
import { listUser, updateUser, updateUserStatus } from "../../services";
import { usePageStore, useUserStore } from "../../stores";
import "./Users.scss";
import { EditUserModal } from "../../components/EditUserModal";

const Users: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { setPage } = usePageStore();
	const [users, setUsers] = useState<ListResult<UserInfoType>>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [targetUser, setTargetUser] = useState<UserInfoType>();
	const { user } = useUserStore();

	useEffectOnce(() => {
		setPage({ title: "List user", pagePath: ["List user"] });

		getData();
	});

	function getData() {
		setIsLoading(true);
		listUser(Object.fromEntries(searchParams) as ListUserQueryType).then(([result]) => {
			setIsLoading(false);
			setUsers(result);
		});
	}
	const setQuery = useCallback((key: string, value: string) => {
		searchParams.set(key, value);
		setSearchParams(searchParams);
		getData();
	}, [searchParams]);

	const sortHandle = useCallback((e: any) => {
		let target = e.target;

		if (target.tagName != "th") {
			target = target.closest("th");
		}

		const col = target.dataset.colName;

		if (searchParams.get("sort") != col) {
			setQuery("sort", col);
		}
		else {
			setQuery("order", searchParams.get("order") == "asc" ? "desc" : "asc");
		}
	}, [searchParams]);

	const getOrderDirection = useCallback((col: string): string => {
		return col != searchParams.get("sort") ? "" : (searchParams.get("order") || "");
	}, [searchParams]);

	useEffect(() => {
		if (!users?.data) {
			return;
		}

		const items = [...users.data];

		items.sort((a, b) => {
			const order = searchParams.get("order");
			const sortCol = searchParams.get("sort") || "userName";

			if (order == "desc") {
				return a[sortCol] < b[sortCol] ? 1 : -1;
			}

			return a[sortCol] > b[sortCol] ? 1 : -1;
		});

		setUsers({ total: users.total, data: items });
	}, [searchParams]);

	const openEditUserModal = (id: number, userEmail: string) => {
		setTargetUser({ id: id, userEmail: userEmail } as UserInfoType);
	}

	const closeModal = () => {
		setTargetUser(undefined);
	}

	const submitEditUser = (model: UserInfoType) => {
		if (!targetUser || (model.userName === targetUser.userName && model.role === targetUser.role)) {
			closeModal();
			return;
		}

		setIsLoading(true);

		model.roleName = Role[model.role];

		updateUser(model).then(([result]) => {
			setIsLoading(false);

			if (result) {
				setUsers((state) => {
					if (!state) {
						return state;
					}

					let data = state.data;

					data.forEach(item => {
						if (item.id === model.id) {
							item = model;
						}
					})

					return { data, total: state?.total };
				});

				toast(`Updated ${model.userEmail}`, { type: "success" });
				getData();
				closeModal();
			}
		})
	}

	const changeStatus = (id: number, isLocked: boolean) => {
		if (id === user?.id || user?.role !== Role.Admin || isLoading) {
			return;
		}

		// eslint-disable-next-line no-restricted-globals
		if (!confirm(!isLocked ? "Ban this user?" : "Unban this user?")) {
			return;
		}

		setIsLoading(true);
		const newStatus = !isLocked;

		updateUserStatus({ id, isLocked: newStatus } as UserInfoType).then(([result]) => {
			setIsLoading(false);
			if (!result) {
				return;
			}

			setUsers((state) => {
				if (!state) {
					return state;
				}

				let data = state.data;

				data.forEach(item => {
					if (item.id === id) {
						item.isLocked = newStatus;
					}
				})

				return { data, total: state?.total };
			});

			toast("Status changed", { type: "success" });
		})
	}

	return (
		<div className="users">
			<Card className="card mb-4">
				<CardHeader>
					<div className="row">
						<div className="col-md-3">
							<SearchBar
								onKeywordChange={key => setQuery("keyword", key)}
								defaultKeyword={searchParams.get("keyword") ?? ""}
								title="Find user" />
						</div>
						<div className="col-md-2 ms-auto text-end">
							<Link className="btn btn-primary btn-add btn-small" color="primary" to="/users/add">Add user</Link>
						</div>
					</div>
				</CardHeader>
				<CardBody className="table-responsive">
					{
						isLoading && <p className="text-center">
							<FontAwesomeIcon icon="spinner" className="fa-spin" />
						</p>
					}
					<Table className="table-striped" hover>
						<thead>
							<tr className="text-capitalize">
								<th onClick={sortHandle} data-col-name="userEmail" className={getOrderDirection("userEmail")}><span className="datatable-sorter">Email</span></th>
								<th onClick={sortHandle} data-col-name="userName" className={getOrderDirection("userName")}><span className="datatable-sorter">Name</span></th>
								<th onClick={sortHandle} data-col-name="roleName" className={getOrderDirection("roleName")}><span className="datatable-sorter">Role</span></th>
								<th onClick={sortHandle} data-col-name="isLocked" className={getOrderDirection("isLocked")}><span className="datatable-sorter">Locked</span></th>
								<th onClick={sortHandle} data-col-name="createdDate" className={getOrderDirection("createdDate")} ><span className="datatable-sorter">Created Date</span></th>
								<th onClick={sortHandle} data-col-name="lastAccessedDate" className={getOrderDirection("lastAccessedDate")} ><span className="datatable-sorter">Last signed in</span></th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{
								users?.data && users.data.length > 0
								&& users.data.map((item: UserInfoType) => {
									return (
										<tr key={item.id}>
											<th scope="row"><Link title="View detail" to={`/users/${item.id}`}>{item.userEmail}</Link></th>
											<td>{item.userName}</td>
											<td>{item.roleName}</td>
											<td>{item.isLocked ? "Yes" : ""}</td>
											<td>{item.createdDate && parseISO(item.createdDate).toLocaleString()}</td>
											<td>{item.lastAccessedDate && parseISO(item.lastAccessedDate).toLocaleString()}</td>
											<td>
												<FontAwesomeIcon icon="edit" onClick={() => openEditUserModal(item.id, item.userEmail)} title="Edit" className="btn" />
												<FontAwesomeIcon icon="file-lines" className="btn" title="View action logs" />
												{
													item.id !== user?.id &&
													<FontAwesomeIcon
														icon={item.isLocked ? "lock" : "check"}
														onClick={() => changeStatus(item.id, item.isLocked)}
														className={!item.isLocked ? "green btn" : "btn"}
														title="Change user state" />
												}
											</td>
										</tr>
									);
								})}
						</tbody>
					</Table>
				</CardBody>
			</Card>

			{
				!targetUser ? null :
					<EditUserModal
						onSubmit={submitEditUser}
						id={targetUser.id}
						userEmail={targetUser.userEmail}
						onClose={closeModal}
						isSaving={isLoading} />
			}
		</div>
	);
}

Users.displayName = "Users";

export default React.memo(Users);