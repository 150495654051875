import "./ListCasino.scss";
import React, { useState } from 'react';
import { usePageStore } from '../../stores';
import { useEffectOnce, useToggle } from 'usehooks-ts';
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addCasinoUser, getCasino, updateCasino, addCasino } from '../../services';
import { CasinoDetailModel } from '../../models';
import { parseISO } from 'date-fns';
import { AddUserCasinoModal, EditCasinoModal, AddCasinoModal } from '../../components';
import { toast } from 'react-toastify';

const ListCasino: React.FC = () => {
	const { setPage } = usePageStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useToggle();
	const [targetCasino, setTargetCasino] = useState<any>({ id: null, action: null });
	const [casinos, setCasinos] = useState<CasinoDetailModel[]>();

	useEffectOnce(() => {
		setPage({ title: "Casinos", pagePath: ["Casinos"] });

		setIsLoading(true);
		getCasino().then(([data]) => {
			setCasinos(data);
			setIsLoading(false)
		})
	});

	const editHandle = (id: string) => {
		setTargetCasino({ id, action: "edit" });
	}

	const addHandle = () => {
		setTargetCasino({ action: "add" });
	}

	const addUserHandle = (id: string) => {
		setTargetCasino({ id, action: "addUser" });
	}

	const closeModal = () => {
		setTargetCasino(undefined);
	}

	const submitAddCasino = (data) => {
		setIsSaving();
		addCasino(data).then(([result]) => {
			setIsSaving();
			toast(`Add Cassino`, { type: "success" });
			closeModal();

			setIsLoading(true);
			getCasino().then(([data]) => {
				setCasinos(data);
				setIsLoading(false)
			})
		})
	}

	const submitAddUser = (data) => {
		setIsSaving();
		addCasinoUser(data).then(([result]) => {
			setIsSaving();

			if (result) {
				toast(`User ${data.email} is added for ${targetCasino}`, { type: "success" });
				closeModal();

				setIsLoading(true);
				getCasino().then(([data]) => {
					setCasinos(data);
					setIsLoading(false)
				})
			}
		})
	}

	const submitEditCasino = (data) => {
		setIsSaving();
		updateCasino(data).then(([result]) => {
			setIsSaving();

			toast(`Updated ${data.id}`, { type: "success" });
			closeModal();

			setIsLoading(true);
			getCasino().then(([data]) => {
				setCasinos(data);
				setIsLoading(false)
			})
		})
	}

	return (
		<div className="list-casino">
			<Card className="card mb-4">
				<CardHeader>
					<div className="row">
						<div className="col-md-3">
						</div>
						<div className="col-md-2 ms-auto text-end">
							{/*<Link className="btn btn-primary" color="primary" to="/games/add">Add new</Link>*/}
							<span className="btn btn-primary" onClick={() => addHandle()} title="Add new">Add new</span>
						</div>
					</div>
				</CardHeader>
				<CardBody className="table-responsive">
					{
						isLoading && <p className="text-center">
							<FontAwesomeIcon icon="spinner" className="fa-spin" />
						</p>
					}
					<Table className="table-striped" hover>
						<thead>
							<tr className="text-capitalize">
								<th><span>Id</span></th>
								<th><span>Name</span></th>
								<th><span>Action</span></th>
							</tr>
						</thead>
						<tbody>
							{
								casinos && casinos.length > 0
								&& casinos.map((item) => {
									return (
										<tr key={item.id} >
											<td>{item.id}</td>
											<td>{item.name}</td>
											<td>
												<span className="btn-link" onClick={() => editHandle(item.id)} title="Edit"><FontAwesomeIcon icon="edit" /></span>
												<span>|</span>
												<span className="btn-link" onClick={() => addUserHandle(item.id)} title="Add user"><FontAwesomeIcon icon="user-plus" /></span>
											</td>
										</tr>
									);
								})}
						</tbody>
					</Table>
				</CardBody>
			</Card>

			{
				targetCasino?.action != "add" ? null :
					<AddCasinoModal
						onSubmit={submitAddCasino}
						onClose={closeModal}
						isSaving={isSaving} />
			}


			{
				targetCasino?.action != "addUser" ? null :
					<AddUserCasinoModal
						onSubmit={submitAddUser}
						casinoId={targetCasino.id}
						onClose={closeModal}
						isSaving={isSaving} />
			}

			{
				targetCasino?.action != "edit" ? null :
					<EditCasinoModal
						onSubmit={submitEditCasino}
						casinoId={targetCasino.id}
						onClose={closeModal}
						isSaving={isSaving} />
			}
		</div>
	);
}

ListCasino.displayName = 'ListCasino';

export default React.memo(ListCasino);