import './SystemTraceFilter.scss';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, FormGroup, Input, Label } from 'reactstrap';
import { DateRangePickerInput } from '../DateRangePicker';
import { useEffectOnce } from 'usehooks-ts';
import { getLogModule, getLogSource, getLogType } from '../../services';
import { SystemTraceFilterModel } from './SystemTraceFilterModel';

type SystemTraceFilterProps = {
	defaultFilter?: SystemTraceFilterModel;
	onFilterChange: (filter: SystemTraceFilterModel) => void;
}

const SystemTraceFilter: React.FC<SystemTraceFilterProps> = ({ onFilterChange, defaultFilter }) => {
	const [logModule, setLogModule] = useState<string[]>();
	const [logType, setLogType] = useState<string[]>();
	const [logSource, setLogSource] = useState<string[]>();
	const [filter, setFilter] = useState<SystemTraceFilterModel>(defaultFilter ?? {} as SystemTraceFilterModel);


	useEffectOnce(() => {
		getLogModule().then(([result]) => {
			setLogModule(result);
		})

		getLogType().then(([result]) => {
			setLogType(result);
		})

		getLogSource().then(([result]) => {
			setLogSource(result);
		})
	});

	useEffect(() => {
		setFilter(defaultFilter || { page: 1, pageSize: 10  } as SystemTraceFilterModel);
	}, [defaultFilter])

	const searchClickHandler = (e) => {
		e.preventDefault();
		onFilterChange(filter);
	}

	const resetClickHandler = (e) => {
		e.preventDefault();

		setFilter(() => {
			const data = {} as SystemTraceFilterModel;
			onFilterChange(data);
			return data;
		});
	}

	return (
		<div className="system-trace-filter">
			<Card className="card mb-4">
				<CardHeader><b>Filter</b></CardHeader>
				<CardBody>
					<div className="row">
						<div className="col-md-3 position-relative">
							<DateRangePickerInput label="Period"
								name="dayPeriod"
								startDate={filter?.fromDate || ""}
								endDate={filter?.toDate || ""}
								onChange={(value) => {
									setFilter({ ...filter, fromDate: value.startDate, toDate: value.endDate });
								}} />
						</div>
						<div className="col-md-3">
							<FormGroup floating>
								<Input
									type="select"
									name="module"
									placeholder="Module"
									required
									value={filter?.module || ""}
									onChange={e => setFilter({ ...filter, module: e.target.value })}
								>
									<option value="">--</option>
									{
										!logModule ? null :
											logModule.map((item: string) => <option key={item} value={item}>{item}</option>)
									}
								</Input>
								<Label for="module">
									Module
								</Label>
							</FormGroup>
						</div>
						<div className="col-md-3">
							<FormGroup floating>
								<Input
									type="select"
									name="type"
									placeholder="Type"
									value={filter.type || ""}
									onChange={e => setFilter({ ...filter, type: e.target.value })}
								>
									<option value="">--</option>
									{
										!logType ? null : logType.map((item: string) => <option key={item} value={item}>{item}</option>)
									}
								</Input>
								<Label for="type">
									Type
								</Label>
							</FormGroup>
						</div>
						<div className="col-md-3">
							<FormGroup floating>
								<Input
									type="select"
									name="source"
									placeholder="Source"
									value={filter.source || ""}
									onChange={e => setFilter({ ...filter, source: e.target.value })}
								>
									<option value="">--</option>
									{
										!logSource ? null : logSource.map((item: string) => <option key={item} value={item}>{item}</option>)
									}
								</Input>
								<Label for="source">
									Source
								</Label>
							</FormGroup>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<FormGroup floating>
								<input type="text"
									placeholder="Keyword"
									maxLength={100}
									value={filter.keyword || ""}
									required
									onChange={e => setFilter({ ...filter, keyword: e.target.value })}
									name="keyword"
									className="form-control"
								/>
								<Label className="form-label" htmlFor="keyword">Keyword</Label>
							</FormGroup>
						</div>
					</div>
				</CardBody>
				<CardFooter>
					<div className="d-flex">
						<div className="ms-auto">
							<button className="btn btn-secondary" type="reset" onClick={resetClickHandler}>Clear</button>
							<button className="btn btn-primary ms-1" type="button" onClick={searchClickHandler}>Search</button>
						</div>
					</div>
				</CardFooter>
			</Card>
		</div>
	);
}

SystemTraceFilter.displayName = 'SystemTraceFilter';

export default React.memo(SystemTraceFilter);