import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { usePageStore } from "../../stores";
import { useEffectOnce } from "usehooks-ts";
import { GameSummary, ListGameQuery, ListResult } from "../../models";
import { getGames, updateGameActiveState, updateGameFeaturedState } from "../../services";

export const useListGame = function () {
	const [gameListResult, setGameListResult] = useState<ListResult<GameSummary>>();
	const [searchParams, setSearchParams] = useSearchParams();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isStateUpdating, setIsStateUpdating] = useState<string | undefined>();
	const { setPage } = usePageStore();

	useEffectOnce(() => {
		setPage({ title: "Game Manage", pagePath: ["List"] });
	});

	useEffect(() => {
		const params = {} as ListGameQuery;
		searchParams.forEach((value, key) => {
			params[key] = value;
		});

		setIsLoading(true);
		getGames(params).then(([data]) => {
			setIsLoading(false);
			setGameListResult(data);
		});

		return () => {
		};
	}, [searchParams]);

	const setQuery = useCallback((key: string, value: string) => {
		searchParams.set(key, value);
		setSearchParams(searchParams);
	}, []);

	const searchChangeHandle = (keyword: string) => {
		setSearchParams((next) => {
			next.set("page", "1");
			next.set("keyword", keyword);
			return next;
		});
	}

	const sortHandle = useCallback((e: any) => {
		let target = e.target;

		if (target.tagName != "th") {
			target = target.closest("th");
		}

		const col = target.dataset.colName;

		if (searchParams.get("sort") != col) {
			setQuery("sort", col);
		}
		else {
			setQuery("order", searchParams.get("order") == "asc" ? "desc" : "asc");
		}
	}, [searchParams]);

	const getOrderDirection = useCallback((col: string): string => {
		return col != searchParams.get("sort") ? "" : (searchParams.get("order") || "");
	}, [searchParams]);

	const pageChangeHandle = useCallback((page: number) => {
		setQuery("page", page.toString());
	}, []);

	const pageSizeChangeHandle = useCallback((pageSize: number) => {
		setSearchParams((next) => {
			next.set("page", "1");
			next.set("pageSize", pageSize.toString());
			return next;
		});
	}, []);

	//const gameFeaturedChangeHandle = (id, isFeature) => {
	//	if (!gameListResult) {
	//		return;
	//	}

	//	setIsStateUpdating(id);
	//	updateGameFeaturedState(id, isFeature).then(([result, error]) => {
	//		setIsStateUpdating(undefined);

	//		if (error || !result) {
	//			return;
	//		}

	//		const items = [...gameListResult.data];
	//		for (const element of items) {
	//			if (element.id == id) {
	//				element.isFeature = isFeature;
	//				break;
	//			}
	//		}

	//		setGameListResult({ ...gameListResult, data: items });
	//	});
	//}

	//const gameActiveChangeHandle = (id, isActive) => {
	//	if (!gameListResult) {
	//		return;
	//	}

	//	setIsStateUpdating(id);
	//	updateGameActiveState(id, isActive).then(([result, error]) => {
	//		setIsStateUpdating(undefined);

	//		if (error || !result) {
	//			return;
	//		}

	//		const items = [...gameListResult.data];
	//		for (const element of items) {
	//			if (element.id == id) {
	//				element.isActive = isActive;
	//				break;
	//			}
	//		}

	//		setGameListResult({ ...gameListResult, data: items });
	//	});
	//}

	return {
		searchParams,
		isLoading,
		gameListResult,
		isStateUpdating,
		getOrderDirection,
		sortHandle,
		searchChangeHandle,
		//gameActiveChangeHandle,
		pageChangeHandle,
		pageSizeChangeHandle,
		//gameFeaturedChangeHandle
	};
}