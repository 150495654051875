import React, { memo, useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './BadgeTagList.scss';

export type BadgeTagListProps = {
	tags: string[];
	onRemove?: (tag) => void;
}

const BadgeTagList: React.FC<BadgeTagListProps> = ({ tags, onRemove }) => {
	const [tagData, setTagData] = useState(tags);
	useEffect(() => {
		setTagData(tags);
	}, [tags]);

	return (
		<div className="badge-tag-list">
			{
				tagData && tagData.map((tag, index) => {
					return (
						<div key={tag ?? index} className="badge bg-success rounded-pill me-1 badge-tag d-inline-flex flex-row align-items-center">
							<span>{tag}</span>
							{
								typeof onRemove == "function" && <FontAwesomeIcon icon={"close"} className="btn-tag-remove" onClick={() => onRemove(tag)} />
							}
						</div>
					);
				})}
		</div>
	);
}

BadgeTagList.displayName = 'BadgeTagList';

export default memo(BadgeTagList);