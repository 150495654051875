import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { UserInfoType } from "../models";
import { getUserSession, signOut } from "../services/user.api";

type UserStore = {
	user?: UserInfoType;
	currentBalance: number;
	setCurrentBalance: (amount: number) => void;
	getUserInfo: () => Promise<UserInfoType | undefined>;
	setUserInfo: (user?: UserInfoType) => void;
	signOut: () => Promise<boolean>;
}

export const useUserStore = create<UserStore>()(
	immer((set) => ({
		currentBalance: 0,
		setCurrentBalance(amount: number) {
			set((state: UserStore) => {
				state.currentBalance = amount;
			});
		},
		async getUserInfo() {
			const [userInfo] = await getUserSession();

			if (userInfo) {
				set((state: UserStore) => {
					state.user = userInfo;
				});
			}

			return userInfo;
		},
		setUserInfo(user?: UserInfoType) {
			set((state: UserStore) => {
				state.user = user;
			});
		},
		async signOut() {
			let [isSuccess] = await signOut();

			set((state: UserStore) => {
				state.user = undefined;
				document.cookie = "GLIS.Admin=\"\"; -1; Path=/";
			});

			return true;
		}
	})),
);