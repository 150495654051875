import {
	BettingTransactionHistoryFilterModel,
	BettingTransactionHistoryResultModel
} from "../components/BettingTransactionHistory";
import { GamePlayingCountType,
	GamesStatisticsFilterModel,
	ListGameOpenCountViewModel, 
	ListResult } from "../models";
import { getData } from "./axios/ConfigAxios";

export async function getGamePlayCountByDay(id: string | null, startDate: string | null, endDate: string | null) {
	return await getData<ListResult<GamePlayingCountType>>(`api/GameViewHistory/play-per-period`, { id, startDate, endDate });
}

export async function getLastPlayedGameByPeriod(startDate: string | null, endDate: string | null) {
	return await getData<ListResult<GamePlayingCountType>>("/api/GameViewHistory/last-played-games", { startDate, endDate });
}

export async function getGameOpenCount(query: GamesStatisticsFilterModel) {
	return await getData<ListResult<ListGameOpenCountViewModel>>("/api/GameViewHistory/open-count", query);
}

export async function getBetHistory(query: BettingTransactionHistoryFilterModel) {
	return await getData<ListResult<BettingTransactionHistoryResultModel>>("/api/game-action-history/bet", query);
}